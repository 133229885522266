import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { BackspaceSharp, CheckCircleOutline, DeleteForever } from "@mui/icons-material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppConfig } from "../../config";
import * as helpers from "../../helpers/helpers";
import * as validateForm from "../../helpers/validateForm";
import StreetSearch from "../../ResusableComponents/StreetSearch";
import ButtonWithTooltip from "../../ResusableComponents/ButtonWithTooltip";
import { toast } from "react-toastify";
import MunicipalityDropDown from "../../ResusableComponents/MunicipalityDropDown";
import AddressContext from "../../Contexts/AddressContext";

const AddressRow = ({ address, setScreenLoading }) => {
  const { PRIMARYLOCATION, updateAddress, removeAddress, deleteAddress } = useContext(AddressContext);
  const firstLoad = useRef(true);
  const [formStyle, setFormStyle] = useState({ borderRadius: "1rem", marginBottom: "1rem", padding: "0.5rem", paddingBottom: "0.15rem", backgroundColor: "rgba(252,91,91, 0)" });
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!firstLoad) {
      formValidation();
    } else {
      firstLoad.current = false;
    }
  }, [errors]);

  useEffect(() => {
    if (address.validAddress === 1) {
      setFormStyle({ ...formStyle, backgroundColor: "rgba(62,217,121, 0.25)" });
    } else if (address.validAddress === 0) {
      setFormStyle({ ...formStyle, backgroundColor: "rgba(252,91,91, 0.25)" });
    } else if (address.validAddress === 2) {
      setFormStyle({ ...formStyle, backgroundColor: "rgba(217,210,62, 0.25)" });
    } else {
      setFormStyle({ ...formStyle, backgroundColor: "rgba(62,217,121, 0)" });
    }
  }, [address.validAddress]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    let changes = [];
    let isValid = {};

    if (name === "streetName") {
      changes.push({ [name]: value.StreetFullName }, { muni: value.MuniName });
      setErrors({ ...errors, [name]: error });
      updateAddress(address.id, changes);
    } else {
      changes.push({ [name]: value });

      //Validate the values
      if (value.length === 0) {
        setErrors({ ...errors, [name]: error });
        if (!_DoesRowHaveErrors() && address.validAddress !== 2) {
          changes.push({ validAddress: 2 });
        }
        updateAddress(address.id, changes);
      } else {
        if (name === "roleNumber") {
          isValid = validateForm.validateARN(value);
        } else if (name === "commonName") {
          isValid = { valid: true, errorMsg: "" };
        } else if (name === "streetNumber") {
          isValid = validateForm.validateStreetNumber(value);
        } else if (name === "planNum") {
          isValid = validateForm.validatePlan(value);
        } else if (name === "lotNum") {
          isValid = validateForm.validateLot(value);
        } else if (name === "unit") {
          isValid = validateForm.validateUnit(value);
        } else if (name === "muni") {
          if (value === "COUNTY OF SIMCOE") {
            isValid.valid = false;
            isValid.errorMsg = "Municipality can not be the County of Simcoe";
          }
        }

        if (!isValid.valid) {
          changes.push({ validAddress: 0 });
        } else {
          changes.push({ validAddress: 2 });
        }
        setErrors({ ...errors, [name]: isValid.errorMsg });
        updateAddress(address.id, changes);
      }
    }
  };

  const _DoesRowHaveErrors = () => {
    return !Object.values(errors).every((err) => err === "");
  };

  const formValidation = () => {
    let isValid = (address.roleNumber || address.planNum || address.lotNum) && Object.values(errors).every((err) => err === "");
    if (isValid === undefined || isValid === "") isValid = false;

    if (address.muni === "COUNTY OF SIMCOE") {
      return false;
    } else {
      return isValid;
    }
  };

  const checkAddress = () => {
    console.log(address);
    if (!formValidation()) {
      if (address.muni === "COUNTY OF SIMCOE") updateAddress(address.id, [{ validAddress: 0 }, { errorMsg: "County of Simcoe is an invalid Municipality option" }]);
      else updateAddress(address.id, [{ validAddress: 0 }, { errorMsg: "Ensure that all field errors are fixed" }]);
    } else {
      const isAddressValid = AppConfig.apiUrl + `secure/address/isAddressValid`;

      helpers.getBearerToken((bearer) => {
        if (bearer.error) {
          //TODO: Alert User of Error
          toast.error("Error getting Authorization Token", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "AddressRowToken",
            className: "errorToast",
          });
          return;
        }

        helpers.getJSONWaitWithParams(
          isAddressValid,
          {
            method: "POST",
            mode: "cors",
            headers: { Authorization: bearer, "Content-Type": "application/json" },
            body: JSON.stringify(address),
          },
          (res) => {
            //TODO: Add toasts below
            console.log(res);
            if (!res.result) {
              updateAddress(address.id, [{ errorMsg: res.error }, { validAddress: 0 }]);
            } else {
              let changes = [
                {
                  validAddress: 1,
                },
                {
                  errorMsg: "",
                },
              ];

              updateAddress(address.id, changes);
              setFormStyle({ ...formStyle, backgroundColor: "rgba(62,217,121, 0.25)" });
              toast.success(`Address has been validated`, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "successToast",
              });
            }
          }
        );
      });
    }
  };

  const handleModal = (btnValue = null) => {
    setOpen(!open);
    if (btnValue) {
      setScreenLoading(true);
      const deletePromise = deleteAddress(address.id);
      deletePromise
        .then((value) =>
          toast.success("Success: Address has been deleted", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "AddressRowDelete",
            className: "successToast",
          })
        )
        .catch((value) =>
          toast.error("Error deleting the address", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "AddressRowDelete",
            className: "errorToast",
          })
        )
        .then((value) => setScreenLoading(false));
    }
  };

  const removeRow = () => {
    removeAddress(address.id);
  };

  return (
    <>
      <Dialog open={open} onClose={handleModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {address.streetNumber} {address.streetName}, {address.muni} from CAM?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleModal(false);
            }}
          >
            No
          </Button>
          <Button
            autoFocus
            onClick={() => {
              handleModal(true);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <form>
        <Grid container spacing={2} style={formStyle}>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <TextField
              error={Boolean(errors?.roleNumber)}
              helperText={errors?.roleNumber}
              autoComplete="off"
              fullWidth
              variant="outlined"
              label="Property #/ARN"
              name="roleNumber"
              value={address.roleNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={1}>
            <TextField
              error={Boolean(errors?.streetNumber)}
              helperText={errors?.streetNumber}
              autoComplete="off"
              type="number"
              fullWidth
              variant="outlined"
              label="Street #"
              name="streetNumber"
              value={address.streetNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <StreetSearch name="streetName" value={address.streetName} setValue={handleChange} muni={PRIMARYLOCATION} />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={1}>
            <TextField error={Boolean(errors?.unit)} autoComplete="off" helperText={errors?.unit} fullWidth variant="outlined" label="Unit" name="unit" value={address.unit} onChange={handleChange} />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={1}>
            <TextField
              error={Boolean(errors?.planNum)}
              autoComplete="off"
              helperText={errors?.planNum}
              fullWidth
              variant="outlined"
              label="Plan #"
              name="planNum"
              value={address.planNum}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={1}>
            <TextField
              error={Boolean(errors?.lotNum)}
              autoComplete="off"
              helperText={errors?.lotNum}
              fullWidth
              variant="outlined"
              label="Lot #"
              name="lotNum"
              value={address.lotNum}
              onChange={handleChange}
            />
          </Grid>
          {PRIMARYLOCATION === "COUNTY OF SIMCOE" ? (
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <MunicipalityDropDown
                id="MuniName"
                name="muni"
                value={address.muni}
                updateFunction={handleChange}
                label="Municipality"
                variant={"outlined"}
                required={true}
                hasError={Boolean(errors?.muni)}
                errorText={errors?.muni}
              />
            </Grid>
          ) : (
            <Grid item xs={6} sm={4} md={2} lg={2}>
              <TextField fullWidth variant="outlined" autoComplete="off" label="Common Name" name="commonName" value={address.commonName} onChange={handleChange} />
            </Grid>
          )}
          <Grid item lg={1} style={{ display: "flex", justifyContent: "left", marginTop: "0.5rem" }}>
            <ButtonGroup>
              <ButtonWithTooltip tooltipText="Validate Row" style={{ width: "50px", height: "auto" }} variant="contained" color="primary" onClick={checkAddress}>
                <CheckCircleOutline />
              </ButtonWithTooltip>
              <ButtonWithTooltip tooltipText="Remove Row" style={{ width: "50px", height: "auto" }} variant="contained" color="secondary" onClick={removeRow}>
                <BackspaceSharp />
              </ButtonWithTooltip>
            </ButtonGroup>
            <ButtonGroup>
              <ButtonWithTooltip
                tooltipText={"Delete Address from CAM"}
                style={{ width: "50px", height: "auto", marginLeft: "0.5rem" }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleModal(null);
                }}
              >
                <DeleteForever />
              </ButtonWithTooltip>
            </ButtonGroup>
          </Grid>
          {address.errorMsg.length > 0 && (
            <Grid item xs={12}>
              <Typography align="center" style={{ color: "Red" }}>
                {address.errorMsg}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default React.memo(AddressRow);

import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useState, useRef } from "react";
import { AppConfig } from "../config";
import * as helpers from "../helpers/helpers";

const StreetSearch = ({ name, value, setValue, muni }) => {
  const [options, setOptions] = useState([]);
  const timeout = useRef();

  const getSearchOptionsURL = (searchTerm) => AppConfig.apiUrl + `secure/search/searchStreets/${encodeURIComponent(searchTerm)}/${encodeURIComponent(muni)}`;

  const handleChange = (e, value, reason) => {
    setValue({ target: { name: name, value: value } });
  };

  const updateSearchOptions = (e) => {
    if (e.length >= 2) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        helpers.getJSONWithToken(getSearchOptionsURL(e), (opts) => {
          setOptions(opts.result);
        });
      }, 300);
    }
  };

  return (
    <Autocomplete
      disableClearable
      freeSolo
      value={value}
      options={options}
      onChange={handleChange}
      clearOnBlur={true}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.StreetFullName)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Street Name"
          onChange={(e) => {
            updateSearchOptions(e.target.value);
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={helpers.getUID()}>
          <div>
            {option.StreetFullName}
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>{option.MuniName}</div>
          </div>
        </li>
      )}
    />
  );
};

export default StreetSearch;

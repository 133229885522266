exports.MapConfig = {
  sections: [
    { id: 1, section: "Basemap", behaviour: "radio" },
    { id: 2, section: "Layer", behaviour: "toggle" },
  ],
  layers: [
    {
      sectionId: 1,

      name: "Topographic",
      visible: true,
      zIndex: 0,
      layers: [
        {
          url: "https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}",
          type: "ESRI_TILED",
          sourceType: "XYZ",
          source: "WMS",
          isOverlay: true,
          excludePrint: true,
          tiled: true,
          name: "World_Hillshade",
        },
        {
          url: "https://tiles.arcgis.com/tiles/B6yKvIZqzuOr0jBR/arcgis/rest/services/Canada_Hillshade/MapServer/tile/{z}/{y}/{x}",
          type: "ESRI_TILED",
          sourceType: "XYZ",
          source: "WMS",
          isOverlay: true,
          excludePrint: true,
          tiled: true,
          name: "Canada_Hillshade",
        },
        {
          url: "https://tiles.arcgis.com/tiles/B6yKvIZqzuOr0jBR/arcgis/rest/services/Canada_Topographic/VectorTileServer",
          type: "ESRI_VECTOR_TILED",
          sourceType: "VectorTile",
          source: "Vector",
          isOverlay: true,
          rootPath: "/cam/basemap/ESRI_Canada_Topographic.json",
          maxZoom: 23,
          excludePrint: true,
          name: "Canada_Topographic",
        },
      ],
    },
    {
      sectionId: 1,
      sourceType: "XYZ",
      source: "WMS",
      layerName: "Arial",
      visible: false,

      url: "https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      tiled: true,
      name: "Arial",
    },
    {
      sectionId: 1,
      sourceType: "TileImage",
      source: "WMS",
      layerName: "Streets",
      visible: false,
      zIndex: 0,

      url: "https://maps.simcoe.ca/arcgis/rest/services/Public/Streets_Black_And_White_Cache/MapServer/tile/{z}/{y}/{x}",
      tiled: true,
      extent: [],
      name: "Streets",
    },
    {
      sectionId: 2,
      sourceType: "ImageWMS",
      source: "WMS",
      visible: true,
      zIndex: 0,

      layerName: "simcoe:Assessment Parcel",
      url: "https://opengis.simcoe.ca/geoserver/ows",
      tiled: false,
      extent: [],
      name: "Assessment Parcel",
    },

    {
      sectionId: 2,
      sourceType: "ImageWMS",
      source: "WMS",
      layerName: "simcoe:Assessment_Parcel_Labels",
      visible: false,
      zIndex: 1,

      url: "https://opengis.simcoe.ca/geoserver/ows",
      tiled: false,
      extent: [],
      name: "Assessment Parcel Labels",
    },

    {
      sectionId: 2,
      sourceType: "WMTS",
      source: "WMTS",
      visible: false,
      zIndex: 1,

      layerName: "Public_911_Ranges_Labels_Cache",
      tiled: false,
      extent: [],
      name: "Address Range Labels",
      url: "https://maps.simcoe.ca/arcgis/rest/services/Public/911_Ranges_Labels_Cache/MapServer/WMTS/1.0.0/WMTSCapabilities.xml",
      projection: "EPSG:3857",
    },

    {
      sectionId: 2,
      sourceType: "ImageWMS",
      source: "WMS",
      visible: false,
      zIndex: 1,

      layerName: "simcoe:Road_Network",
      url: "https://opengis.simcoe.ca/geoserver/ows",
      tiled: false,
      extent: [],
      name: "Streets",
    },
  ],
  parcelLayer: {
    url: "https://opengis.simcoe.ca/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=simcoe:Search_Addresses&outputFormat=application/json",
    rollNumberFieldName: "arn",
    childRollNumberFieldName: "childarn",
  },
  addressSearch: {
    url: "https://opengis.simcoe.ca/geoserver/",
    layerName: "simcoe:Address_Number",
  },
};

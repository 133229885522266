import "./RecipientForm.css";

import { ButtonGroup, TextField, Typography } from "@mui/material";
import { Backspace, Save } from "@mui/icons-material";
import { useEffect, useState } from "react";
import * as helpers from "../../helpers/helpers.js";
import ButtonWithTooltip from "../../ResusableComponents/ButtonWithTooltip";
import { AppConfig } from "../../config";
import { toast } from "react-toastify";
import MunicipalityDropDown from "../../ResusableComponents/MunicipalityDropDown";

const RecipientForm = ({ recipient, setRecipient, addRecipientToCurrent, updateCurrentRecipient, clearRecipient }) => {
  const [isValid, setIsValid] = useState(false);

  const isRecipientValid = () => {
    const emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (recipient.Name === "" || recipient.Email === "" || recipient.Organization === "" || recipient.MuniName === "") return false;
    if (!emailRegx.test(recipient.Email)) return false;

    return true;
  };

  useEffect(() => {
    const valid = isRecipientValid();
    setIsValid(valid);
  }, [recipient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecipient({ ...recipient, [name]: value });
  };

  const handleSave = async () => {
    const operationRoute = recipient.id === -1 ? "addReportRecipient" : "updateReportRecipient";
    const outputString = recipient.id === -1 ? "Added" : "Updated";

    const url = `${AppConfig.apiUrl}secure/reports/${operationRoute}`;

    helpers.getBearerToken((bearer) => {
      if (bearer.error) {
        toast.error("Error getting Authentication Token", {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "ReportToken",
          className: "errorToast",
        });
      }
      helpers.getJSONWithParams(
        url,
        {
          method: "POST",
          mode: "cors",
          headers: { Authorization: bearer, "Content-Type": "application/json" },
          body: JSON.stringify(recipient),
        },
        (res) => {
          const result = res.result;
          if (result.error) {
            //TODO: Handle if there's an error
            toast.error("Error Saving report recipient.", {
              position: "bottom-left",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "ReportSave",
              className: "errorToast",
            });
          } else {
            if (recipient.id === -1) {
              addRecipientToCurrent(result.output);
            } else {
              let recip = recipient;
              recip.ModifiedBy = window.account.name;
              recip.ModifiedDate = new Date().toLocaleDateString();
              updateCurrentRecipient(recip);
            }

            toast.success(`${recipient.Name} has been successfully ${outputString}`, {
              position: "bottom-left",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "ReportSave",
              className: "successToast",
            });

            clearRecipient();
          }
        }
      );
    });
  };

  return (
    <>
      <Typography variant="h5">Add/Edit Report Recipients</Typography>
      <div className="inputs">
        <TextField id="name" name="Name" value={recipient.Name} onChange={handleChange} label="Name" variant="outlined" required />
        <TextField id="email" name="Email" value={recipient.Email} onChange={handleChange} type="email" label="Email" variant="outlined" required />
        <TextField id="org" name="Organization" value={recipient.Organization} onChange={handleChange} label="Organization" variant="outlined" required />
        <MunicipalityDropDown id="MuniName" name="MuniName" value={recipient.MuniName} updateFunction={handleChange} label="Municipality" variant={"outlined"} required={true} />
        <div className="updateInfo">
          {recipient.CreatedBy !== null && (
            <div>
              <Typography style={{ display: "inline-block", marginRight: "2rem" }} variant="subtitle2">
                Created By: {recipient.CreatedBy}
              </Typography>
              <Typography style={{ display: "inline-block" }} variant="subtitle2">
                Created On: {new Date(recipient.CreatedDate).toLocaleDateString()}
              </Typography>
            </div>
          )}
          {recipient.ModifiedBy !== null && (
            <div>
              <Typography style={{ display: "inline-block", marginRight: "2rem" }} variant="subtitle2">
                Modified By: {recipient.ModifiedBy}
              </Typography>
              <Typography style={{ display: "inline-block" }} variant="subtitle2">
                Modified On: {new Date(recipient.ModifiedDate).toLocaleDateString()}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "inline-block", width: "100%" }}>
        <ButtonGroup style={{ float: "right" }}>
          <ButtonWithTooltip tooltipText="Save the form" variant="contained" color="primary" onClick={handleSave} disabled={!isValid}>
            <Save />
          </ButtonWithTooltip>
          <ButtonWithTooltip tooltipText="Clear the form" variant="contained" color="secondary" onClick={() => clearRecipient()}>
            <Backspace />
          </ButtonWithTooltip>
        </ButtonGroup>
      </div>
    </>
  );
};

export default RecipientForm;

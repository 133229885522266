import "./RecipientTable.css";

import { Paper, TablePagination } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { AppConfig } from "../../config";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";
const RecipientTable = ({ setRecipient, currentRecipients, setCurrentRecipients, clearRecipient }) => {
  const [tableRows, setTableRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  //Pagination Variables
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    searchTable(searchTerm);
  }, [currentRecipients]);

  const searchTable = (value) => {
    const filteredRows = currentRecipients.filter((row) => {
      return (
        (row.Name || "").toLowerCase().includes(value.toLowerCase()) ||
        (row.Email || "").toLowerCase().includes(value.toLowerCase()) ||
        (row.Organization || "").toLowerCase().includes(value.toLowerCase()) ||
        (row.MuniName || "").toLowerCase().includes(value.toLowerCase())
      );
    });
    setTableRows(filteredRows);
  };

  const handleEdit = (data) => {
    data.isExisting = true;
    setRecipient(data);
  };

  const handleDelete = async (id, name) => {
    const url = AppConfig.apiUrl + `secure/reports/removeReportRecipient/${id}`;
    //TODO: Use a Promise
    helpers.getJSONWithToken(url, (res) => {
      if (!res.result.error) {
        setCurrentRecipients(currentRecipients.filter((recipient) => recipient.id !== id));
        setTableRows(tableRows.filter((row) => row.id !== id));
        clearRecipient(true, id);

        toast.success(`${name} has been successfully removed`, {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "ReportSave",
          className: "successToast",
        });
      } else {
        toast.error("Error Deleting selected recipient", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "ReportDelete",
          className: "errorToast",
        });
      }
    });
  };

  return (
    <Paper>
      <MaterialTable
        columns={[
          { title: "Name", field: "Name" },
          { title: "Email", field: "Email" },
          { title: "Organization", field: "Organization" },
          { title: "Municipality", field: "MuniName" },
        ]}
        // data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
        data={tableRows}
        title="Recipients"
        actions={[
          {
            icon: () => <Delete />,
            tooltip: "Delete Recipient",
            onClick: (event, rowData) => {
              handleDelete(rowData.id, rowData.Name);
            },
          },
          {
            icon: () => <Edit />,
            tooltip: "Edit Recipient",
            onClick: (event, rowData) => {
              handleEdit(rowData);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          rowStyle: {
            fontSize: "11px",
          },
        }}
        components={{
          Pagination: (props, ref) => <TablePagination {...props} rowsPerPageOptions={[10, 50, 100]} />,
        }}
      />

      {/* <SearchBar
        className="searchBar"
        value={searchTerm}
        onChange={(value) => {
          searchTable(value);
          setSearchTerm(value);
        }}
        onCancelSearch={cancelSearch}
        placeholder="Search..."
      /> */}
      {/* <TableContainer>
        <Table aria-label="Current Report Recipient table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Municipality</TableCell>
              <TableCell style={{ textAlign: "center" }}>Edit/Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={helpers.getUID()}>
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.Email}</TableCell>
                <TableCell>{row.Organization}</TableCell>
                <TableCell>{row.MuniName}</TableCell>
                <TableCell style={{ margin: "0 auto", textAlign: "center" }}>
                  <Tooltip title="Edit Recipient">
                    <IconButton
                      color="primary"
                      aria-label="Edit Recipient"
                      size="medium"
                      onClick={() => {
                        handleEdit(row);
                      }}
                    >
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove Recipient">
                    <IconButton
                      color="secondary"
                      aria-label="Delete Recipient"
                      size="medium"
                      onClick={() => {
                        handleDelete(row.id, row.Name);
                      }}
                    >
                      <DeleteForever fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer> */}
    </Paper>
  );
};

export default RecipientTable;

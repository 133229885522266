import { Paper } from "@mui/material";

const InProgress = ({ inProgress }) => {
  const containerStyle = {
    width: "50%",
    margin: "12rem auto",
  };

  const contentStyle = {
    padding: "1rem 1rem",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Paper elevation={2}>
        <div style={contentStyle}>
          <h2>Redirecting in Progress. Please wait.</h2>
        </div>
      </Paper>
    </div>
  );
};

export default InProgress;

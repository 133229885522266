import React, { useEffect } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import AddressManagement from "./AddressManagement/AddressManagement";
import ReportDashboard from "./ReportDashboard/ReportDashboard";
import AddEditStreets from "./AddEditStreets/AddEditStreets";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header/Header";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication, InteractionStatus } from "@azure/msal-browser";
import { msalConfig, getUserLocations } from "./authConfig";
import { ErrorBoundary } from "./ErrorBoundary.jsx";
import NotFound from "./UtilityComponents/NotFound";
import InProgress from "./UtilityComponents/InProgress";
import ShowError from "./UtilityComponents/ShowError";
import Unauthenticated from "./UtilityComponents/Unauthenticated";
import { ToastContainer } from "react-toastify";
import { AddressProvider } from "./Contexts/AddressContext";
import { StyledEngineProvider } from "@mui/material/styles";
import packageJson from "../package.json";

window.isAppSecured = true;
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
function App(props) {
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
    </MsalProvider>
  );
}

function handleLogin(instance) {
  instance.loginRedirect(msalConfig).catch((e) => {
    console.error(e);
  });
}
const clearUserToken = () => {
  const tenantId = msalConfig.tenant;
  //remove previous cached login information
  Object.keys(localStorage).forEach((key) => {
    if (key.indexOf("login.microsoftonline.com") !== -1 || key.indexOf(tenantId) !== -1 || key.indexOf("msal.token.keys") !== -1) {
      localStorage.removeItem(key);
    }
  });
};
const versionCleanup = () => {
  const cacheKey = "cam-cacheVersion";

  if (packageJson.version !== localStorage.getItem(cacheKey)) {
    let previousVersion = localStorage.getItem(cacheKey);
    previousVersion = !previousVersion ? "0.0.0" : previousVersion;
    let majorVersion = parseInt(previousVersion.split(".")[0]);
    let minorVersion = parseInt(previousVersion.split(".")[1]);
    let patchVersion = parseInt(previousVersion.split(".")[2]);
    console.log("Version has changed from " + previousVersion + " to " + packageJson.version);
    console.log("Major Version: " + majorVersion);
    console.log("Minor Version: " + minorVersion);
    console.log("Patch Version: " + patchVersion);
    localStorage.removeItem(cacheKey);
    localStorage.setItem(cacheKey, packageJson.version);
    //ADD ANY CLEANUP CODE HERE
    if (majorVersion <= 1 && minorVersion <= 0 && patchVersion <= 0) clearUserToken(); //remove old authentication tokens
  }
};
const MainContent = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    versionCleanup();

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      handleLogin(instance);
    } else {
      let account = undefined;
      const tenantId = msalConfig.tenant;
      if (accounts.length === 0) return;
      account = accounts.filter((item) => {
        return item.tenantId === tenantId;
      })[0];
      if (!account) {
        //something went wrong, clear the user login cache and try again
        handleLogin(instance);
      }
      window.instance = instance;
      window.account = account;
      window.security = account.idTokenClaims ? account.idTokenClaims.roles : [];
      window.locations = getUserLocations();
    }
  }, [isAuthenticated, instance, accounts, inProgress]);

  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        <ErrorBoundary>
          <MsalAuthenticationTemplate interactionType="redirect" loadingComponent={InProgress} errorComponent={ShowError}>
            <Router>
              <div>
                <Header></Header>
                <Routes>
                  <Route path="/cam/report" element={<ReportDashboard />} />
                  <Route path="/cam/addEditStreets" element={<AddEditStreets />} />
                  <Route path="/cam" element={<AddressManagement />} />
                  <Route exact path="/" element={<AddressManagement />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </Router>
          </MsalAuthenticationTemplate>
        </ErrorBoundary>

        <UnauthenticatedTemplate>
          <Unauthenticated />
        </UnauthenticatedTemplate>
        <ToastContainer theme="colored" position="top-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </StyledEngineProvider>
  );
};

export default App;

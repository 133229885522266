import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as AuthConfig from "../authConfig";
import { AppConfig } from "../config";
import * as helpers from "../helpers/helpers";

const AddressContext = createContext();

function defaultAddress(location) {
  return { commonName: "", fullAddress: "", id: helpers.getUID(), lotNum: "", muni: location, planNum: "", roleNumber: "", streetName: "", streetNumber: "", unit: "", validAddress: 3 };
}

function getDefaultAddresses(location) {
  let rows = [];

  rows.push(defaultAddress(location));
  rows.push(defaultAddress(location));

  return rows;
}

export function AddressProvider({ children }) {
  const PRIMARYLOCATION = AuthConfig.getUserLocations()[0];
  const [editAddresses, setEditAddresses] = useState([]);
  const [addAddressToTemplate, setAddAddressToTemplate] = useState(false);
  const [addressTemplate, setAddressTemplate] = useState({
    propertyNum: "",
    street: "",
    settlement: PRIMARYLOCATION,
    commonName: "",
    planNum: "",
    fromStreet: "",
    increment: 0,
    quantity: 1,
  });

  const addAddressForEdit = (addresses) => {
    setEditAddresses(addresses.map((addr) => (addr.id !== null ? { ...addr, id: String(addr.id) } : {})));
  };

  // Function used to add a new Default Address row to the editor
  const addDefaultAddressRow = () => {
    setEditAddresses([...editAddresses, defaultAddress()]);
  };

  //UseEffect used to get default Addresses on load
  useEffect(() => {
    setEditAddresses(rebuildAddresses());
  }, []);

  // Set the Address Template to the first address in the array
  // IF addAddressToTemplate is TRUE
  // ELSE clear out the values
  useEffect(() => {
    if (addAddressToTemplate) {
      const address = editAddresses[0];
      setAddressTemplate({
        propertyNum: address.roleNumber || "",
        street: address.streetName || "",
        settlement: PRIMARYLOCATION,
        commonName: address.commonName || "",
        planNum: address.planNum || "",
        fromStreet: address.streetNumber || "",
        increment: 0,
        quantity: 1,
      });
    } else {
      setAddressTemplate({
        propertyNum: "",
        street: "",
        settlement: PRIMARYLOCATION,
        commonName: "",
        planNum: "",
        fromStreet: "",
        increment: 0,
        quantity: 1,
      });
    }
  }, [addAddressToTemplate]);

  const setAddressesFromTemplate = (template) => {
    let rows = [];
    for (let i = 0; i < template.quantity; i++) {
      const addr = {
        commonName: template.commonName,
        fullAddress: "",
        id: helpers.getUID(),
        lotNum: "",
        muni: template.settlement,
        planNum: template.planNum,
        roleNumber: template.propertyNum,
        streetName: template.street,
        streetNumber: template.increment > 0 ? String(parseInt(template.fromStreet) + i * template.increment) : template.fromStreet,
        unit: "",
        validAddress: 2,
      };

      rows.push(addr);
    }

    addAddressForEdit(rows);
  };

  function removeAddress(id, isArray = false) {
    setEditAddresses(rebuildAddresses(id, isArray));
  }

  function updateAddress(id, changes) {
    let a = editAddresses.find((addr) => addr.id === id);

    for (const change of changes) {
      for (const key in change) {
        a[key] = change[key];
      }
    }
    setEditAddresses(editAddresses.map((addr) => (addr.id === id ? a : addr)));
  }

  function deleteAddress(id) {
    const deleteAddressURL = AppConfig.apiUrl + `secure/address/deleteAddress/${id}/0`;
    return new Promise((resolve, reject) => {
      helpers.getJSONWithToken(deleteAddressURL, (res) => {
        if (res.result.error) {
          reject("Error: Could not delete Address");
        } else {
          if (res.result) {
            removeAddress(id);
            resolve(true);
          }
        }
      });
    });
  }

  async function saveAddress(address) {
    const url = AppConfig.apiUrl + "secure/address/saveAddress";
    return new Promise((resolve, reject) => {
      helpers.getBearerToken((bearer) => {
        if (bearer.error) {
          let res = {
            result: {
              error: 1,
              output: "Error getting Authorization Token",
              id: address.id,
            },
          };
          reject(res);
        }
        helpers.getJSONWaitWithParams(
          url,
          {
            method: "POST",
            mode: "cors",
            headers: { Authorization: bearer, "Content-Type": "application/json" },
            body: JSON.stringify(address),
          },
          (res) => {
            res.result.id = address.id;

            if (res.result.error) {
              if (res.result.output.length === 0) {
                res.result.output = "An Error occured while Saving";
              }
              reject(res);
            } else {
              resolve(res);
            }
          }
        );
      });
    });
  }

  function saveAll() {
    let savePromises = [];
    let removeIDs = [];
    editAddresses.forEach((address, index) => {
      if (address.validAddress !== 3) {
        setTimeout(
          () => {
            savePromises = [];
            savePromises.push(saveAddress(address));
            Promise.allSettled(savePromises)
              .then((responses) => {
                responses.forEach((resp) => {
                  let result;
                  if (resp.status === "fulfilled") {
                    result = resp.value.result;

                    if (result.error) {
                      const changes = [{ validAddress: 0 }, { errorMsg: result.output }];
                      updateAddress(result.id, changes);
                      toast.error(`Failed to save ${getStreetAddress(result.id)}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "errorToast",
                      });
                    } else {
                      toast.success(`Saved: ${getStreetAddress(result.id)}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "successToast",
                      });

                      removeIDs.push(result.id);
                    }
                  } else {
                    result = resp.reason.result;

                    const changes = [{ validAddress: 0 }, { errorMsg: result.output }];
                    updateAddress(result.id, changes);
                    toast.error(`Failed to save ${getStreetAddress(result.id)}`, {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      className: "errorToast",
                    });
                  }
                });
              })
              .then(() => {
                removeAddress(removeIDs, true);
                rebuildIndexes().catch((e) => {
                  toast.error(e, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "AddressEditorRebuild",
                    className: "errorToast",
                  });
                  console.log("Error", e);
                });
              })
              .catch((e) => {
                console.log("ERRORS: ", e);
                toast.error("An error occured while saving", {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "AddressEditorRebuild",
                  className: "errorToast",
                });
              });
          },
          index === 0 ? 0 : 1000 * (index + 1) // COULDN'T GET PROMISES WORKING PROPERLY.  PATCHED IT
        );
      }
    });
  }

  function rebuildIndexes() {
    const url = AppConfig.apiUrl + `secure/search/rebuildIndexes/${PRIMARYLOCATION}`;
    return new Promise((resolve, reject) => {
      helpers.getJSONWithToken(url, (res) => {
        if (res.result.error) {
          reject("An Error has occured.");
        } else {
          resolve(res);
        }
      });
    });
  }

  function rebuildAddresses(id = undefined, isArray = false) {
    if (id === undefined) {
      return getDefaultAddresses(PRIMARYLOCATION);
    }

    let rows = [];

    if (!isArray) {
      rows = editAddresses.filter((address) => address.id !== id);
    } else {
      rows = editAddresses.filter((address) => !id.includes(address.id));
    }

    if (rows < 1) {
      return getDefaultAddresses();
    }

    return rows;
  }

  function getStreetAddress(id) {
    // Get Address from ID
    const addr = editAddresses.find((address) => address.id === id);
    // Determine if the Unit should be shown or not
    const unit = addr.unit === null || addr.unit === undefined || addr.unit === "" ? "" : `Unit ${addr.unit}`;
    //Return a formatted string of the Address
    return `${addr.streetNumber} ${addr.streetName} ${unit}`;
  }

  return (
    <AddressContext.Provider
      value={{
        PRIMARYLOCATION,
        editAddresses,
        addAddressForEdit,
        addressTemplate,
        setAddressTemplate,
        setAddressesFromTemplate,
        setAddAddressToTemplate,
        addDefaultAddressRow,
        getDefaultAddresses,
        updateAddress,
        saveAll,
        removeAddress,
        deleteAddress,
        rebuildAddresses,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export default AddressContext;

import { Button, Tooltip } from "@mui/material";

const ButtonWithTooltip = ({ tooltipText, tooltipPlacement, disabled, onClick, ...other }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };

  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement ? tooltipPlacement : "bottom"}>
      <span>
        <Button {...other} {...adjustedButtonProps} />
      </span>
    </Tooltip>
  );
};

export default ButtonWithTooltip;

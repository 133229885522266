import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as helpers from "../../helpers/helpers";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { StyledEngineProvider } from "@mui/material/styles";

const SearchBar = ({ apiURL, municipality, allMuni, setSearchTerm }) => {
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  const timeout = useRef();
  //API CALL Helper Functions
  const getSearchOptionsURL = (searchTerm, municiaplity, allMunicipalities) =>
    apiURL + `secure/search/getSearchOptions/${encodeURIComponent(searchTerm)}/${encodeURIComponent(municiaplity)}/${encodeURIComponent(allMunicipalities)}`;

  useEffect(() => {
    if (search.length >= 2) {
      handleDebounceSearch();
    } else {
      setOptions([]);
      setSearchTerm({ text: "", type: "" });
    }
  }, [search, allMuni]);

  const handleDebounceSearch = () => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      getSearchOptions();
    }, 300);
  };

  const getSearchOptions = () => {
    helpers.getJSONWithToken(getSearchOptionsURL(search, municipality, allMuni), (opts) => {
      if (opts.result.error) {
        //TODO: Add Error Result
        toast.error("Error getting typeahead results", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "SearchBar",
          className: "errorToast",
        });
        setOptions([]);
      } else {
        setOptions(opts.result);
      }
    });
  };

  const handleSelect = (e, value, reason) => {
    if (reason !== "clear") {
      if (value !== null || value !== "") {
        const selectedOption = options.filter((opt) => opt.Name === value.Name);
        if (selectedOption.length !== 0) {
          setSearchTerm({ text: value.Name, type: value.type, muni: value.Muni });
        }
      }
    }
  };

  return (
    // <StyledEngineProvider injectFirst>
    <Autocomplete
      disableClearable
      freeSolo
      id="searchInput"
      value={search}
      options={options}
      onChange={handleSelect}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.Name)}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={helpers.getUID()}>
          <div>
            {option.Name}
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>
              {option.Muni} ({option.type})
            </div>
          </div>
        </li>
      )}
      // renderOption={(props, option) => {
      //   console.log(props);
      //   console.log(option);
      //   return (
      //     // <div key={helpers.getUID()}>{props.key}</div>
      //     <div key={option.Name + option.Muni + option.type}>
      //       {option.Name}
      //       <div style={{ fontSize: "12px", fontWeight: "bold" }}>
      //         {option.Muni} ({option.type})
      //       </div>
      //     </div>
      //   );
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          label="Search"
          placeholder="Search ARN, Street, Address..."
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
    />
    // </StyledEngineProvider>
  );
};

export default SearchBar;

import "./ReportDashboard.css";

import { Container, Paper, Typography } from "@mui/material";
import { Assessment } from "@mui/icons-material";
import RecipientForm from "./RecipientForm/RecipientForm";
import { useEffect, useState } from "react";
import RecipientTable from "./RecipientTable/RecipientTable";
import * as helpers from "../helpers/helpers.js";
import { AppConfig } from "../config";
import { toast } from "react-toastify";

const ReportDashboard = () => {
  const [currentRecipients, setCurrentRecipients] = useState([]);
  const [recipient, setRecipient] = useState({
    id: -1,
    Name: "",
    Email: "",
    Organization: "",
    MuniName: "COUNTY OF SIMCOE",
    CreatedDate: null,
    CreatedBy: null,
    ModifiedDate: null,
    ModifiedBy: null,
    isExisting: false,
  });

  useEffect(() => {
    const recipientUrl = AppConfig.apiUrl + `secure/reports/reportRecipients`;
    helpers.getJSONWithToken(recipientUrl, (res) => {
      if (res.result.error) {
        toast.error("Error getting a list of recipients", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "ReportGetAll",
          className: "errorToast",
        });
      } else {
        setCurrentRecipients(res.result);
      }
    });
  }, []);

  const addRecipientToCurrent = (id) => {
    let newRecipient = recipient;
    newRecipient.CreatedDate = new Date().toLocaleDateString();
    newRecipient.CreatedBy = window.account.name;
    newRecipient.id = id;
    setCurrentRecipients((currentRecipients) => [...currentRecipients, newRecipient]);
  };

  const updateCurrentRecipient = (recip) => {
    setCurrentRecipients(currentRecipients.map((x) => (x.id === recip.id ? recip : x)));
  };

  const clearRecipient = (onDelete = false, id = null) => {
    if (!onDelete) {
      setRecipient({
        id: -1,
        Name: "",
        Email: "",
        Organization: "",
        MuniName: "COUNTY OF SIMCOE",
        CreatedDate: null,
        CreatedBy: null,
        ModifiedDate: null,
        ModifiedBy: null,
        isExisting: false,
      });
    } else {
      if (recipient.id === id) {
        setRecipient({
          id: -1,
          Name: "",
          Email: "",
          Organization: "",
          MuniName: "COUNTY OF SIMCOE",
          CreatedDate: null,
          CreatedBy: null,
          ModifiedDate: null,
          ModifiedBy: null,
          isExisting: false,
        });
      }
    }
  };

  return (
    <main>
      <Container maxWidth="xl">
        <Paper elevation={2} className="customPaper">
          <div className="iconAndText pageTitle">
            <Assessment style={{ fontSize: 43 }} color="primary" />
            <Typography variant="h4">Report Dashboard</Typography>
          </div>
          <div className="content">
            <div className="recipientForm">
              <RecipientForm
                recipient={recipient}
                setRecipient={setRecipient}
                addRecipientToCurrent={addRecipientToCurrent}
                updateCurrentRecipient={updateCurrentRecipient}
                clearRecipient={clearRecipient}
              />
            </div>
            <div className="recipientTable">
              <RecipientTable setRecipient={setRecipient} currentRecipients={currentRecipients} setCurrentRecipients={setCurrentRecipients} clearRecipient={clearRecipient} />
            </div>
          </div>
        </Paper>
      </Container>
    </main>
  );
};

export default ReportDashboard;

export function validateARN(value) {
  const regex = new RegExp("^[4][3]([0-9])+$");
  let result = { valid: true, errorMsg: "" };

  if (value.trim().length !== 15) {
    result.valid = false;
    result.errorMsg = "ARN must be 15 digits long";
    return result;
  }

  if (!regex.test(value)) {
    result.valid = false;
    result.errorMsg = "ARN is incorrectly formatted";
    return result;
  }

  return result;
}

export function validateStreetNumber(value) {
  const regex = new RegExp("[0-9]+$");
  let result = { valid: true, errorMsg: "" };

  if (!regex.test(value)) {
    result.valid = false;
    result.errorMsg = "Only numbers are allowed";
    return result;
  }

  return result;
}

export function validatePlan(value) {
  let result = { valid: true, errorMsg: "" };

  if (value.length >= 3) {
    if (value.toLowerCase().startsWith("pl")) {
      const planNumber = value.substring(2);
      if (planNumber.length > 0 && !planNumber.match(/-?[0-9]+$/)) {
        result.valid = false;
        result.errorMsg = "PL Plans can only contain a hyphen and numbers";
        return result;
      }
    } else if (value.startsWith("51")) {
      if (!value.includes("-")) {
        result.valid = false;
        result.errorMsg = "Proper format requires a hyphen";
        return result;
      }

      const planType = value.toLowerCase().split("-");

      if (planType[0] !== "51m" && planType[0] !== "51r" && planType[0] !== "51scp") {
        result.valid = false;
        result.errorMsg = "Plan type is not valid";
        return result;
      }

      if (!planType[1].match(/^\d{3,10}$/)) {
        result.valid = false;
        result.errorMsg = "Plans can only have 3 to 10 numbers";
        return result;
      }
    }
  } else {
    result.valid = false;
    result.errorMsg = "Plan # is not long enough";
    return result;
  }

  return result;
}

export function validateUnit(value) {
  const regex = new RegExp("([a-zA-Z])[0-9]{1,5}([a-zA-Z])?$");  
  let result = { valid: true, errorMsg: "" };

  if (value.length > 5) {
    result.valid = false;
    result.errorMsg = "Unit length is too long";
    return result;
  }

  if (value.length > 1) {
    if (value.charAt(0).match(/[a-zA-Z]/i)) {
      if (!regex.test(value)) {
        result.valid = false;
        result.errorMsg = "Invalid Unit Format";
        return result;
      }
    }
  }

  return result;
}

export function validateLot(value) {
  const regex = new RegExp("[0-9]+$");
  let result = { valid: true, errorMsg: "" };

  if (!regex.test(value)) {
    result.valid = false;
    result.errorMsg = "Only numbers are allowed";
    return result;
  }

  if (parseInt(value) < 1 || parseInt(value) > 50000) {
    result.valid = false;
    result.errorMsg = "Lot number is out of range";
    return result;
  }

  return result;
}

import "./Header.css";
import { AppBar, Button, CssBaseline, IconButton, Toolbar, Typography } from "@mui/material";
import { LocationOnTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as helpers from "../helpers/helpers";
import UserProfile from "./UserProfile.jsx";

const Header = (props) => {
  return (
    <header>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Link to="/" className="navLink">
            <IconButton edge="start" color="inherit" aria-label="logo-icon" size="large">
              <LocationOnTwoTone fontSize="large" />
            </IconButton>
          </Link>
          <Link to="/" className="navLink">
            <Typography variant="h6">CAM - Address Management</Typography>
          </Link>
          <div className="navLinks">
            <Link to="/" className="navLink">
              <Button color="inherit">Addresses</Button>
            </Link>
            {helpers.isAllowed(window.security, ["admin"]) && (
              <Link to="/cam/report" className="navLink">
                <Button color="inherit">Report</Button>
              </Link>
            )}
            {helpers.isAllowed(window.security, ["admin"]) && (
              <Link to="/cam/addEditStreets" className="navLink">
                <Button color="inherit">Add/Edit Streets</Button>
              </Link>
            )}
          </div>
          <UserProfile />
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import { List, ListItem, Checkbox, ListItemText, Collapse } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import "./LayerToggler.css";

const LayerToggler = (props) => {
  const [open, setOpen] = useState(false);
  const [layers, setLayers] = useState(props.layers);
  useEffect(() => {
    setLayers(props.layers);
  }, [props.layers]);
  const updateLayerVisibility = (changes) => {
    setLayers(
      layers.map((item) => {
        if (changes[item.layerId] !== undefined) item.visible = changes[item.layerId];
        return item;
      })
    );
  };
  const onItemSelected = (layerId, sectionId, behaviour, callback) => {
    let layerChanges = {};
    const mapLayers = window.map.getLayers();
    let layerCount = 0;
    const maxLayerCount = mapLayers.getLength();
    mapLayers.forEach((layer) => {
      layerCount++;
      if (behaviour === "radio") {
        if (layer.get("sectionId") === sectionId) {
          layerChanges[layer.get("layerId")] = layer.get("layerId") === layerId;
          layer.setVisible(layer.get("layerId") === layerId);
        }
      } else {
        if (layer.get("layerId") === layerId) {
          const visible = !layer.getVisible();
          layerChanges[layerId] = visible;
          layer.setVisible(visible);
        }
      }
      if (layerCount >= maxLayerCount) updateLayerVisibility(layerChanges);
    });
  };

  return (
    <div id="sc-layer-toggler" className={`sc-layer-toggler`} onMouseOut={() => setOpen(false)} onMouseOver={() => setOpen(true)}>
      <List dense={true} disablePadding={true}>
        <ListItem button={true} alignItems="flex-start" dense={true} onClick={() => setOpen(!open)}>
          <ListItemText primary={"Map Layers"} />

          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto">
          {props.sections.map((section) => {
            return <LayerSection key={`LayerSection-${section.id}`} section={section} layers={layers} onItemSelected={onItemSelected} />;
          })}
        </Collapse>
      </List>
    </div>
  );
};

const LayerSection = (props) => {
  const [open, setOpen] = useState(false);
  const [layers, setLayers] = useState(props.layers.filter((layer) => layer.sectionId === props.section.id));
  useEffect(() => {
    setLayers(props.layers.filter((layer) => layer.sectionId === props.section.id));
  }, [props.section, props.layers]);
  return (
    <React.Fragment>
      <ListItem button={true} divider={true} onClick={() => setOpen(!open)} dense={true}>
        <ListItemText primary={props.section.section} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding dense={true}>
          {layers.map((layer) => {
            return (
              <LayerRow
                key={`LayerRow-${layer.layerId}}`}
                layerName={layer.layerName}
                visible={layer.visible}
                layerId={layer.layerId}
                sectionId={layer.sectionId}
                behaviour={props.section.behaviour}
                onClick={props.onItemSelected}
              />
            );
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
const LayerRow = (props) => {
  const [isSelected, setIsSelected] = useState(props.visible);
  const itemClicked = () => {
    // if (props.behaviour === "toggle") {
    //   props.onClick(props.layerId, props.sectionId, props.behaviour);
    // } else {
    props.onClick(props.layerId, props.sectionId, props.behaviour);
    // setIsSelected(true);
    // }
  };
  useEffect(() => {
    setIsSelected(props.visible);
  }, [props.visible]);
  return (
    <React.Fragment>
      <ListItem button={true} onClick={itemClicked} className={`sc-layer-toggler-layer${isSelected ? " selected" : ""}`} dense={true}>
        {props.behaviour === "toggle" ? <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": `label-${props.layerId}` }} /> : ""}
        <ListItemText id={`label-${props.layerId}`} primary={props.layerName} />
      </ListItem>
    </React.Fragment>
  );
};

export default LayerToggler;

import "./AddEditStreets.css";

import { Container, Paper, TextField, Typography, Autocomplete, ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import * as helpers from "../helpers/helpers.js";
import { AppConfig } from "../config";
import { EditLocation, Save, Cancel } from "@mui/icons-material";
import ButtonWithTooltip from "../ResusableComponents/ButtonWithTooltip";

const AddEditStreetsPanel = ({ editStreet, editMode, onSave, onCancel, onValueChange }) => {
  const getAllLookupsURL = () => AppConfig.apiUrl + `secure/address/getAllLookups`;

  const [munis, setMunis] = useState([]);
  const [directions, setDirections] = useState([]);
  const [suffixes, setSuffixes] = useState([]);
  const [prefixes, setPrefixes] = useState([]);
  const [streetsAll, setStreetsAll] = useState([]);
  const [muni, setMuni] = useState({ label: "", value: null });
  const [directionPrefix, setDirectionPrefix] = useState({ label: "", value: "" });
  const [directionSuffix, setDirectionSuffix] = useState({ label: "", value: null });
  const [suffix, setSuffix] = useState({ label: "", value: null });
  const [prefix, setPrefix] = useState({ label: "", value: null });
  const [street, setStreet] = useState("");
  const [editMsg, setEditMsg] = useState("");
  const [muniError, setMuniError] = useState("");
  const [streetError, setStreetError] = useState("");

  useEffect(() => {
    if (munis.length === 0) {
      // GET ALL THE LOOKUPS IN ONE CALL
      helpers.getJSONWithToken(getAllLookupsURL(), (opts) => {
        console.log(opts.result);
        setMunis(opts.result.munis);
        setSuffixes(opts.result.suffixes);
        setStreetsAll(opts.result.streets);
        setPrefixes(opts.result.prefixes);
        setDirections(opts.result.directions);
      });
    }

    setMuni({ label: editStreet.MuniName, value: editStreet.MuniId });
    setDirectionPrefix({ label: editStreet.DirectionPrefixName, value: editStreet.DirectionPrefixId });
    setDirectionSuffix({ label: editStreet.DirectionName, value: editStreet.DirectionID });
    setSuffix({ label: editStreet.StreetType, value: editStreet.SuffixID });
    setPrefix({ label: editStreet.PrefixName, value: editStreet.PrefixId });
    setStreet(editStreet.StreetName);

    if (editStreet.StreetFullName !== undefined && editMode === "EDIT") {
      setEditMsg("You are now editing: " + editStreet.StreetFullName + " - " + editStreet.MuniName);
    } else if (editMode === "ADD") {
      setEditMsg("You are now creating a NEW street.");
    } else {
      setEditMsg("");
    }
  }, [editStreet, editMode]);

  const handleSave = () => {
    let streetToBeSaved = {
      DirectionPrefixId: null,
      DirectionPrefixName: "",
      PrefixId: null,
      PrefixName: "",
      StreetID: null,
      StreetName: "",
      SuffixID: null,
      StreetType: "",
      DirectionID: null,
      DirectionName: "",
      MuniId: null,
      MuniName: "",
      StreetFullName: "",
    };
    if (directionPrefix !== null) {
      streetToBeSaved.DirectionPrefixId = directionPrefix.value;
      streetToBeSaved.DirectionPrefixName = directionPrefix.label;
    }

    if (prefix !== null) {
      streetToBeSaved.PrefixId = prefix.value;
      streetToBeSaved.PrefixName = prefix.label;
    }

    streetToBeSaved.StreetID = editStreet.StreetID;
    streetToBeSaved.StreetName = street;

    if (suffix !== null) {
      streetToBeSaved.SuffixID = suffix.value;
      streetToBeSaved.StreetType = suffix.label;
    }

    if (directionSuffix !== null) {
      streetToBeSaved.DirectionID = directionSuffix.value;
      streetToBeSaved.DirectionName = directionSuffix.label;
    }

    streetToBeSaved.MuniId = muni.value;
    streetToBeSaved.MuniName = muni.label;

    console.log(streetToBeSaved);

    setMuniError("");
    setStreetError("");
    let hasError = false;
    if (muni.value === undefined || muni.value === null) {
      setMuniError("Municipality is Required");
      hasError = true;
    }
    if (street === "") {
      setStreetError("Street is Required");
      hasError = true;
    }

    if (!hasError) {
      onSave(streetToBeSaved);
    }
  };

  const handleCancel = () => {
    setMuniError("");
    setStreetError("");
    onCancel();
  };
  return (
    <main>
      <Container maxWidth="xl">
        <Paper elevation={2} className="customPaper">
          <div className="iconAndText pageTitle" style={editStreet.StreetFullName !== undefined && editMode === "EDIT" ? { marginBottom: "0px" } : { marginBottom: "5px" }}>
            <EditLocation fontSize="medium" color="primary" />
            <Typography variant="h6">Editor</Typography>
          </div>
          <div style={editStreet.StreetFullName !== undefined && editMode === "EDIT" ? { marginTop: "-5px", marginBottom: "10px" } : {}}>
            <Typography style={{ color: "green", fontWeight: "bold", paddingTop: "10px" }} variant="h8">
              {editMsg}
            </Typography>
          </div>
          <div className={editMode === "" ? "sc-disabled" : ""}>
            <Autocomplete
              disabled={editMode === "" ? true : false}
              disablePortal
              disableClearable
              options={munis}
              sx={{ width: 400 }}
              renderInput={(params) => <TextField {...params} error={muniError === "" ? false : true} helperText={muniError} label="Municipality" />}
              value={muni}
              style={{ paddingBottom: "5px", backgroundColor: "white", marginTop: "10px" }}
              onChange={(event, newValue) => {
                setMuni(newValue);
                if (newValue !== undefined && newValue !== null) {
                  setMuniError("");
                }
              }}
            />
            <Autocomplete
              disabled={editMode === "" ? true : false}
              disablePortal
              options={directions}
              sx={{ width: 400 }}
              renderInput={(params) => <TextField {...params} label="Direction Prefix" />}
              value={directionPrefix}
              style={{ paddingBottom: "5px", backgroundColor: "white" }}
              onChange={(event, newValue) => {
                setDirectionPrefix(newValue);
              }}
            />
            <Autocomplete
              disabled={editMode === "" ? true : false}
              disablePortal
              options={prefixes}
              sx={{ width: 400 }}
              renderInput={(params) => <TextField {...params} label="Prefix" />}
              value={prefix}
              style={{ paddingBottom: "5px", backgroundColor: "white" }}
              onChange={(event, newValue) => {
                setPrefix(newValue);
              }}
            />
            <TextField
              error={streetError === "" ? false : true}
              helperText={streetError}
              disabled={editMode === "" ? true : false}
              label="Street Name"
              value={street}
              style={{ paddingBottom: "5px", backgroundColor: "white" }}
              onChange={(e) => {
                setStreet(e.target.value);
                if (e.target.value !== "") {
                  setStreetError("");
                }
              }}
              sx={{ width: 400 }}
            />
            <Autocomplete
              disabled={editMode === "" ? true : false}
              disablePortal
              options={directions}
              style={{ paddingBottom: "5px", backgroundColor: "white" }}
              sx={{ width: 400 }}
              renderInput={(params) => <TextField {...params} label="Direction Suffix" />}
              value={directionSuffix}
              onChange={(event, newValue) => {
                setDirectionSuffix(newValue);
              }}
            />
            <Autocomplete
              disabled={editMode === "" ? true : false}
              disablePortal
              options={suffixes}
              style={{ backgroundColor: "white" }}
              sx={{ width: 400 }}
              renderInput={(params) => <TextField {...params} label="Suffix" />}
              value={suffix}
              onChange={(event, newValue) => {
                setSuffix(newValue);
              }}
            />
          </div>
          <div>
            <ButtonGroup style={{ marginTop: "5px" }}>
              <ButtonWithTooltip
                disabled={editMode === "" ? true : false}
                tooltipText={"Save This Street"}
                style={{ width: "50px", height: "auto", marginLeft: "0.5rem" }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleSave();
                }}
              >
                <Save fontSize="large" />
              </ButtonWithTooltip>
              <ButtonWithTooltip
                disabled={editMode === "" ? true : false}
                tooltipText={"Cancel"}
                style={{ width: "50px", height: "auto", marginLeft: "0.5rem" }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleCancel();
                }}
              >
                <Cancel fontSize="large" />
              </ButtonWithTooltip>
            </ButtonGroup>
          </div>
        </Paper>
      </Container>
    </main>
  );
};

export default AddEditStreetsPanel;

import { useState, useEffect } from "react";
import "./Map.css";
import Map from "ol/Map";
import View from "ol/View";
import { Vector as VectorLayer } from "ol/layer.js";
import GeoJSON from "ol/format/GeoJSON.js";
import { Vector as VectorSource, Cluster } from "ol/source.js";
import { defaults as defaultControls } from "ol/control.js";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style.js";

import { MouseWheelZoom, defaults as defaultInteractions } from "ol/interaction";
import { LayerHelpers } from "../../helpers/OLHelpers";
import { MapConfig } from "./config";
import * as helpers from "../../helpers/helpers";
import LayerToggler from "./LayerToggler";
import { useCallback } from "react";

const MapWidget = (props) => {
  const styleCache = {};
  const [vectorSource, setVectorSource] = useState(new VectorSource());
  const [vectorPointSource, setVectorPointSource] = useState(new VectorSource());
  const [centerCoords, setCenterCoords] = useState([-8878504.68, 5543492.45]);
  const [defaultZoom, setDefaultZoom] = useState(10);

  const [vectorLayer, setVectorLayer] = useState(
    new VectorLayer({
      zIndex: 500,
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: "#E78080",
          width: 3,
        }),
        fill: new Fill({
          color: [231, 128, 128, 0.3],
        }),
      }),
    })
  );
  const [vectorPointLayer, setVectorPointLayer] = useState(
    new VectorLayer({
      zIndex: 501,
      source: new Cluster({
        distance: 10,
        minDistance: 20,
        source: vectorPointSource,
      }),
      style: function (feature) {
        const size = feature.get("features").length;
        let style = styleCache[size];
        if (!style) {
          style = new Style({
            stroke: new Stroke({
              color: "#E78080",
              width: 3,
            }),
            fill: new Fill({
              color: [231, 128, 128, 0.3],
            }),
            image: new CircleStyle({
              radius: 8,
              stroke: new Stroke({
                color: "#E78080",
              }),
              fill: new Fill({
                color: [231, 128, 128, 1],
              }),
            }),
            text: new Text({
              text: size == 1 ? "" : size.toString(),
              fill: new Fill({
                color: "#fff",
              }),
            }),
          });

          styleCache[size] = style;
        }
        return style;
      },
    })
  );
  const [layers, setLayers] = useState([]);
  const [sections, setSections] = useState([]);
  const [pointFeatures, setPointFeatures] = useState([]);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    vectorPointSource.clear();

    if (pointFeatures.length === 0) return;
    vectorPointSource.addFeatures(pointFeatures);

    const extent = vectorPointSource.getExtent();
    window.map.getView().fit(extent, window.map.getSize(), { duration: 500 });
    window.map.getView().setZoom(window.map.getView().getZoom() - 1, { duration: 500 });

    window.map.updateSize();
  }, [pointFeatures, vectorPointSource]);

  useEffect(() => {
    vectorSource.clear();
    if (features.length === 0) return;
    vectorSource.addFeatures(features);
    const extent = vectorSource.getExtent();
    window.map.getView().fit(extent, window.map.getSize(), { duration: 500 });
    window.map.getView().setZoom(window.map.getView().getZoom() - 1, { duration: 500 });

    window.map.updateSize();
  }, [features, vectorSource]);

  useEffect(() => {
    if (props.resultCount !== undefined && props.resultCount == 0) resetView();
  }, [props.resultCount]);

  const resetView = () => {
    vectorPointSource.clear();
    vectorSource.clear();
    window.map.getView().setCenter(centerCoords);
    window.map.getView().setZoom(defaultZoom);
  };
  useEffect(() => {
    /* if (props.loading) {
      return;
    } */

    if (props.selection.length > 0) {
      const parcelURLARNTemplate = (mainURL, rollNumberFieldName, childRollNumberFieldName, arns) =>
        `${mainURL}&cql_filter=${rollNumberFieldName} IN (${arns.join(",")}) OR ${childRollNumberFieldName} IN (${arns.join(",")})`;
      let parcelURL = "";
      if (props.selection.length > 25) {
        let recordChunks = Math.ceil(props.selection.length / 25);
        let allFeatures = [];
        for (let i = 0; i < recordChunks; i++) {
          let chunkStart = i * 25;
          let records = props.selection.slice(chunkStart, chunkStart + 25);
          parcelURL = parcelURLARNTemplate(MapConfig.parcelLayer.url, MapConfig.parcelLayer.rollNumberFieldName, MapConfig.parcelLayer.childRollNumberFieldName, records);
          helpers.getJSON(parcelURL, (result) => {
            if (result.features.length > 0) allFeatures = allFeatures.concat(new GeoJSON().readFeatures(result));
            setFeatures(allFeatures);
          });
        }
      } else {
        parcelURL = parcelURLARNTemplate(MapConfig.parcelLayer.url, MapConfig.parcelLayer.rollNumberFieldName, MapConfig.parcelLayer.childRollNumberFieldName, props.selection);
        helpers.getJSON(parcelURL, (result) => {
          if (result.features.length === 0) return;
          setFeatures(new GeoJSON().readFeatures(result));
        });
      }
    }
  }, [props.selection, props.loading]);

  const updateMap = useCallback(() => {
    if (props.searchTerm && props.searchTerm.type !== "Assessment Parcel" && props.searchTerm.text) {
      let sql = "";
      if (props.municipality && !props.searchAll) sql += `muni = '${props.municipality}'`;

      if (props.searchTerm.text) {
        if (sql === "") sql += `fullname ILIKE '%25${props.searchTerm.text}%25'`;
        else sql += `AND fullname ILIKE '%25${props.searchTerm.text}%25'`;
      }

      helpers.getWFSGeoJSON(
        MapConfig.addressSearch.url,
        MapConfig.addressSearch.layerName,
        (result) => {
          setPointFeatures(result);
          props.setLoading(false);
        },
        "stnum,fullname",
        null,
        sql,
        1000
      );
    }
  }, [props.municipality, props.searchTerm, props.searchAll, props.setLoading]);

  useEffect(() => {
    if (props.loading) {
      updateMap();
    }
  }, [props.loading, updateMap]);

  const initMap = () => {
    var controls = [];

    let minZoom = 9;
    let maxZoom = 18;
    const map = new Map({
      controls: defaultControls({ rotate: false }).extend(controls.concat([])),
      layers: [],
      target: "map",
      view: new View({
        center: centerCoords,
        zoom: defaultZoom,
        maxZoom: maxZoom,
        minZoom: minZoom,
      }),
      interactions: defaultInteractions({
        keyboard: true,
        mouseWheelZoom: false,
        altShiftDragRotate: false,
        pinchRotate: false,
      }).extend([
        new MouseWheelZoom({
          duration: 0,
          constrainResolution: true,
        }),
      ]),
      keyboardEventTarget: document,
    });
    window.map = map;
    vectorLayer.setZIndex(500);
    window.map.addLayer(vectorLayer);
    vectorPointLayer.setZIndex(501);
    window.map.addLayer(vectorPointLayer);

    setSections(MapConfig.sections);
    loadLayers();
  };
  const addLayer = (layer, opts, callback) => {
    let currentLayerId = helpers.getUID();
    let visible = false;
    let currentSectionId = opts && opts.sectionId !== undefined ? opts.sectionId : 1;
    layer.setProperties({ layerId: currentLayerId, sectionId: currentSectionId });
    if (opts && opts.zIndex !== undefined) layer.setZIndex(opts.zIndex);
    if (opts && opts.visible !== undefined) visible = opts.visible;
    layer.setVisible(visible);
    window.map.addLayer(layer);
    callback({ layerName: layer.get("name"), layerId: currentLayerId, sectionId: currentSectionId, visible: visible });
  };
  const loadLayers = () => {
    let itemCount = 0;
    let allLayerValues = [];
    MapConfig.layers.forEach((source) => {
      itemCount++;
      if (source.layers) {
        LayerHelpers.getGroupedLayer(source, (retLayer) => {
          addLayer(retLayer, { zIndex: source.zIndex, sectionId: source.sectionId, visible: source.visible }, (layerValues) => {
            allLayerValues.push(layerValues);
            if (itemCount === MapConfig.layers.length) setLayers(allLayerValues);
          });
        });
      } else {
        LayerHelpers.getLayer(source, (retLayer) => {
          addLayer(retLayer, { zIndex: source.zIndex, sectionId: source.sectionId, visible: source.visible }, (layerValues) => {
            allLayerValues.push(layerValues);
            if (itemCount === MapConfig.layers.length) setLayers(allLayerValues);
          });
        });
      }
    });
  };

  return (
    <div id="map-root" style={{ height: "100%" }}>
      <div id="map" className={"sc-map-container"} />
      <LayerToggler layers={layers} sections={sections} />
    </div>
  );
};
export default MapWidget;

exports.AppConfig = {
  userId: 230,
  apiUrl: "https://cam.simcoe.ca/camApi/",
  municipality: "ESSA",
  azureScope: "fdc68f03-9cf3-45ef-92a1-7d070a5de889/client_access",
};
exports.AppConfigDev = {
  userId: 230,
  apiUrl: "https://giswebdev.simcoe.ca/camApi/",
  municipality: "ESSA",
  azureScope: "fdc68f03-9cf3-45ef-92a1-7d070a5de889/client_access",
};

exports.AppConfigDevLocal = {
  userId: 230,
  apiUrl: "http://localhost:8093/",
  municipality: "ESSA",
  azureScope: "fdc68f03-9cf3-45ef-92a1-7d070a5de889/client_access",
};

exports.AppConfigProd = {
  userId: 230,
  apiUrl: "https://cam.simcoe.ca/camApi/",
  municipality: "ESSA",
  azureScope: "fdc68f03-9cf3-45ef-92a1-7d070a5de889/client_access",
};

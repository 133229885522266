import { Paper } from "@mui/material";

const Unauthenticated = () => {
  const containerStyle = {
    width: "50%",
    margin: "12rem auto",
  };

  const contentStyle = {
    padding: "1rem 1rem",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Paper elevation={2}>
        <div style={contentStyle}>
          <h2>You are Unautherized to access the content.</h2>
          <h4>Please Sign in to access the site</h4>
        </div>
      </Paper>
    </div>
  );
};

export default Unauthenticated;

import { Paper } from "@mui/material";

const NotFound = () => {
  const containerStyle = {
    width: "50%",
    margin: "12rem auto",
  };

  const contentStyle = {
    padding: "1rem 1.5rem",
  };

  return (
    <div style={containerStyle}>
      <Paper elevation={2}>
        <div style={contentStyle}>
          <h1>Nothing Here - 404</h1>
          <h4>It looks like we couldn't find the page you were looking for.</h4>
        </div>
      </Paper>
    </div>
  );
};

export default NotFound;

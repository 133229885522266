import { useState, useEffect, useContext } from "react";
import "./Search.css";
import * as helpers from "../../helpers/helpers";
import { Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircleOutline, CloudDownloadOutlined, CreateOutlined, Room } from "@mui/icons-material";
import SearchBar from "./SearchBar";
import MapWidget from "../Map/Map.jsx";
import ButtonWithTooltip from "../../ResusableComponents/ButtonWithTooltip";
import MunicipalityDropDown from "../../ResusableComponents/MunicipalityDropDown";
import { toast } from "react-toastify";
import AddressContext from "../../Contexts/AddressContext";
import { StyledEngineProvider } from "@mui/material/styles";

const Search = ({ apiURL }) => {
  const { PRIMARYLOCATION, setAddAddressToTemplate, addAddressForEdit } = useContext(AddressContext);
  // DECLARE COMPONENT VARIABLES
  const [isParcel, setIsParcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState({ text: "", type: "" });
  const [searchAll, setSearchAll] = useState(PRIMARYLOCATION !== "COUNTY OF SIMCOE" ? false : true);
  const [selectedAddressIDs, setSelectedAddressIDs] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchRows, setSearchRows] = useState([]);
  const [searchMuni, setSearchMuni] = useState(PRIMARYLOCATION);
  //Loading is used for displaying loading circle when retrieving addresses
  const [loading, setLoading] = useState(false);
  //DoneLoading is passed to map once loading is completed to fix map Refresh height
  const [doneLoading, setDoneLoading] = useState(false);
  //VARIABLE FOR MAP ROLE NUMBERS
  const [mapARN, setMapARN] = useState([]);
  //SET HEADERS FOR DATAGRID
  const [searchTableColumns, setSearchTableColumns] = useState([]);
  //VARIABLE FOR DATAGRID PAGE SIZE
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  //FUNCTION TO BUILD URL
  const getSearchResultsURL = (searchTerm, type, searchMuni, allMunicipalities) =>
    apiURL + `secure/search/getSearchResults/${encodeURIComponent(searchTerm)}/${encodeURIComponent(type)}/${encodeURIComponent(searchMuni)}/${encodeURIComponent(allMunicipalities)}`;

  useEffect(() => {
    if (searchTerm.text.length >= 2) {
      setLoading(true);
      getSearchResults();
    } else {
      setSearchResults([]);
      setSelectedAddressIDs([]);
      setMapARN([]);
      setSearchMuni(PRIMARYLOCATION);
    }
  }, [searchTerm, searchAll]);

  const getSearchResults = () => {
    //GET RESULTS
    helpers.getJSONWithToken(getSearchResultsURL(searchTerm.text, searchTerm.type, PRIMARYLOCATION, searchAll), (res) => {
      if (res.result.error) {
        //TODO: Alert user of Error
        toast.error("Error Getting Search Results", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "SearchBar",
          className: "errorToast",
        });
      } else {
        let parcelIsAddress = res.result[0].isParcel;

        //CHECK IF ANY RESULTS RETURNED ARE ADDRESSES IF TYPE IS PARCEL

        if (searchTerm.type === "Assessment Parcel" && parcelIsAddress) {
          setIsParcel(true);
        } else {
          setIsParcel(false);
        }
        setSearchTableColumns([
          { field: "roleNumber", headerName: "ARN", flex: 0.75 },
          { field: "unit", headerName: "Unit", flex: 0.5 },
          { field: "fullAddress", headerName: "Address", flex: 1 },
          { field: "muni", headerName: "Municipality", flex: 1 },
          {
            field: "map",
            headerName: "Map",
            sortable: false,
            flex: 0.75,
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
              return (
                <div className="mapButton" key={helpers.getUID()}>
                  <Tooltip title="Show on Map" placement="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        setMapARN([params.row.roleNumber]);
                      }}
                    >
                      <Room />
                    </Button>
                  </Tooltip>
                </div>
              );
            },
          },
        ]);

        setDoneLoading(true);
        setSearchResults(res.result);
        setSearchRows(res.result.filter((row) => row.muni === String(searchTerm.muni).toUpperCase()));
        setSearchMuni(String(searchTerm.muni).toUpperCase());

        console.log(searchTerm);
        console.log(res.result);
        console.log(res.result.filter((row) => row.muni === String(searchTerm.muni).toUpperCase()));
        console.log(searchResults.length);
        console.log(searchRows);
      }
      setLoading(false);
    });
  };

  const addSelectedAddress = () => {
    let selectedAddress = searchResults.filter((result) => selectedAddressIDs.includes(result.id));
    selectedAddress.map((a) => {
      a.validAddress = 1;
      return a;
    });
    addAddressForEdit(selectedAddress);
    if (isParcel) {
      setAddAddressToTemplate(true);
    } else {
      setAddAddressToTemplate(false);
    }

    // CLEAR ALL THE RESULTS OR JUST THE SELECTED ITEM?
    // setSearchRows(searchResults.filter((result) => !selectedAddressIDs.includes(result.id)));
    setSearchRows([]);
  };

  const getMapARN = () => {
    const selected = searchResults.filter((result) => selectedAddressIDs.includes(result.id));
    let arns = [];
    selected.forEach((a) => {
      arns.push(a.roleNumber);
    });

    setMapARN(arns);
  };

  const exportAddresses = () => {
    const addressesForExport = searchResults.filter((result) => selectedAddressIDs.includes(result.id));
    const fieldsToExport = [
      { commonName: "Common Name" },
      { lotNum: "Lot Number" },
      { planNum: "MPlan/RPlan" },
      { muni: "Municipality" },
      { roleNumber: "Property No." },
      { streetName: "Street Name" },
      { streetNumber: "Street Number" },
      { unit: "Unit" },
    ];
    //Get the Fields and User Friendly Headers
    let headerRow = [];
    let fields = [];
    for (const field of fieldsToExport) {
      for (const key in field) {
        fields.push(key);
        headerRow.push(field[key]);
      }
    }

    const replaceNulls = function (key, value) {
      return value === null ? "" : value;
    };

    let csv = addressesForExport.map((row) => {
      return fields
        .map((fieldName) => {
          return JSON.stringify(row[fieldName], replaceNulls);
        })
        .join(",");
    });

    csv.unshift(headerRow);
    csv = csv.join("\r\n");
    const csvContent = "data:text/csv;charset=utf-8," + csv;
    const encodedUri = encodeURI(csvContent);

    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "address_export.csv");
    document.body.appendChild(link);

    link.click();
  };

  const updateSearchMuni = (e) => {
    setSearchMuni(e.target.value);
    filterRows(e.target.value);
  };

  const filterRows = (muni) => {
    setPage(0);
    if (muni === "COUNTY OF SIMCOE") {
      setSearchRows(searchResults);
      return;
    }

    if (searchResults.length < 1) {
      return;
    }

    setSearchRows(searchResults.filter((row) => row.muni === muni));
  };

  return (
    // <StyledEngineProvider injectFirst>
    <Grid container spacing={2} item={true}>
      <Grid item={true} sm={12} md={12} lg={7}>
        <Grid item={true} container spacing={2} xs={12} style={{ marginBottom: "1.25rem", marginTop: "0.25rem" }}>
          <Grid item xs={12} sm={8}>
            <SearchBar apiURL={apiURL} setSearchTerm={setSearchTerm} allMuni={searchAll} municipality={PRIMARYLOCATION} />
          </Grid>
          <Grid item xs={12} sm={4}>
            {PRIMARYLOCATION !== "COUNTY OF SIMCOE" ? (
              <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={helpers.getUID()}
                      checked={searchAll}
                      onChange={() => {
                        setSearchAll(!searchAll);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      color="primary"
                    />
                  }
                  label="Include All Municipalities?"
                />
              </div>
            ) : (
              <MunicipalityDropDown id="searchMuni" name="searchMuni" value={searchMuni} label="Municipality Filter" variant={"outlined"} updateFunction={updateSearchMuni} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}

          {searchResults.length > 0 && !loading && (
            <DataGrid
              // key={helpers.getUID()}
              disableSelectionOnClick
              autoHeight
              pagination
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 15, 25]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              columns={searchTableColumns}
              rows={searchRows}
              getRowId={(row) => row.id}
              onSelectionModelChange={setSelectedAddressIDs}
              isRowSelectable={(params) => params.row.muni === PRIMARYLOCATION || PRIMARYLOCATION === "COUNTY OF SIMCOE"}
              checkboxSelection
              disableColumnMenu
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
      <Grid item sm={12} lg={5} sx={{ minHeight: "500px", height: "auto", position: "relative", marginTop: "0.25rem" }}>
        <MapWidget
          key={"cam-3-map-widget"}
          selection={mapARN}
          municipality={PRIMARYLOCATION}
          searchTerm={searchTerm}
          searchAll={searchAll}
          loading={doneLoading}
          setLoading={setDoneLoading}
          resultCount={searchResults.length}
        />
      </Grid>
      <Grid item>
        {searchResults.length > 0 && (
          <>
            {!isParcel ? (
              <ButtonGroup style={{ marginTop: "1rem" }}>
                <ButtonWithTooltip
                  variant="contained"
                  color="primary"
                  onClick={addSelectedAddress}
                  disabled={selectedAddressIDs.length === 0}
                  endIcon={<CreateOutlined />}
                  tooltipText="Edit Selected Addresses"
                >
                  {"Edit Selected"}
                </ButtonWithTooltip>
                <ButtonWithTooltip
                  variant="contained"
                  color="inherit"
                  onClick={exportAddresses}
                  endIcon={<CloudDownloadOutlined />}
                  disabled={selectedAddressIDs.length === 0}
                  tooltipText="Export Selected Addresses"
                >
                  {"Export Selected"}
                </ButtonWithTooltip>
              </ButtonGroup>
            ) : (
              <ButtonWithTooltip
                tooltipText="Use Selected as Address Template"
                style={{ marginTop: "1rem" }}
                variant="contained"
                color="primary"
                onClick={addSelectedAddress}
                disabled={selectedAddressIDs.length === 0}
                endIcon={<AddCircleOutline />}
              >
                {"Add to Template"}
              </ButtonWithTooltip>
            )}

            <ButtonWithTooltip
              tooltipText="View Selected Addresses on the Map"
              style={{ marginLeft: "2rem" }}
              variant="contained"
              color="primary"
              endIcon={<Room />}
              onClick={getMapARN}
              disabled={selectedAddressIDs.length === 0}
            >
              {"View Selected"}
            </ButtonWithTooltip>
          </>
        )}
      </Grid>
    </Grid>
    // </StyledEngineProvider>
  );
};

export default Search;

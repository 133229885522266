import { useCallback, useContext, useEffect, useState } from "react";
import "./AddressEditor.css";
import { Button, Container, IconButton, Tooltip } from "@mui/material";
import AddTemplate from "./AddTemplate";
import AddressRow from "./AddressRow";
import { AddCircleOutlined, Save } from "@mui/icons-material";
import AddressContext from "../../Contexts/AddressContext";

const AddressEditor = ({ setScreenLoading }) => {
  const { addDefaultAddressRow, editAddresses, getDefaultAddresses, saveAll } = useContext(AddressContext);
  const [addressRows, setAddressRows] = useState([]);

  // Create array for addressRows
  const getAddressRows = useCallback(() => {
    let rows = [];
    let tempAddresses = [];

    if (editAddresses.length > 0) {
      tempAddresses = [...editAddresses];
    } else {
      tempAddresses = getDefaultAddresses();
    }

    tempAddresses.forEach((address) => {
      rows.push(
        <AddressRow
          key={address.id}
          address={{
            commonName: address.commonName || "",
            fullAddress: address.fullAddress || "",
            id: address.id,
            lotNum: address.lotNum || "",
            muni: address.muni,
            planNum: address.planNum || "",
            roleNumber: address.roleNumber || "",
            streetName: address.streetName || "",
            streetNumber: address.streetNumber || "",
            unit: address.unit || "",
            validAddress: address.validAddress,
            errorMsg: address.errorMsg || "",
          }}
          setScreenLoading={setScreenLoading}
        />
      );
    });

    setAddressRows(rows);
  }, [editAddresses]);

  useEffect(() => {
    getAddressRows();
  }, [editAddresses, getAddressRows]);

  const handleSaveAll = () => {
    setScreenLoading(true);
    saveAll();
    setScreenLoading(false);
  };
  return (
    <Container maxWidth="xl">
      <div>
        <h3>Address Template</h3>
        <div className="AddTemplateForm">
          <AddTemplate />
        </div>
      </div>
      <br />
      <div>
        <div className="headerWithButton">
          <h3>Address Editing</h3>
          <Tooltip title="Add new Address Row" placement="top">
            <Button endIcon={<AddCircleOutlined />} color="primary" component="span" size="large" onClick={addDefaultAddressRow}>
              Add Row
            </Button>
          </Tooltip>
        </div>
        <div className="EditAddressArea">{addressRows}</div>
        <div>
          <Tooltip title="Save All Valid Addresses" placement="right">
            <Button endIcon={<Save />} variant="contained" color="primary" onClick={handleSaveAll}>
              Save All
            </Button>
          </Tooltip>
        </div>
      </div>
    </Container>
  );
};

export default AddressEditor;

import { Paper } from "@mui/material";

const ShowError = ({ error }) => {
  const containerStyle = {
    width: "50%",
    margin: "12rem auto",
  };

  const contentStyle = {
    padding: "1rem 1rem",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Paper elevation={2}>
        <div style={contentStyle}>
          <h2>This Web Map is a secured page and the below error occurred during authentication</h2>
          <p>Please contact your system administrator if this issue continues.</p>
          <h4>
            <strong>Error: {error.errorCode}</strong>
          </h4>
        </div>
      </Paper>
    </div>
  );
};

export default ShowError;

import { ScreenLockRotationSharp } from "@mui/icons-material";

// Config object to be passed to Msal on creation
export const msalConfig = {
  tenant: "baae5256-5a73-40f2-8c4d-76f3ae971463",
  auth: {
    clientId: "f5b30356-db09-4783-9402-bba4662df75b",
    authority: "https://login.microsoftonline.com/baae5256-5a73-40f2-8c4d-76f3ae971463/v2.0/.well-known/openid-configuration",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
export const msalConfigDev = {
  auth: {
    authority: "https://login.microsoftonline.com/8b09f628-abf6-49f1-b848-ed6eeeca2898",
    clientId: "3fe95846-f731-43a6-b150-55f2581c0194",
    aud: "3fe95846-f731-43a6-b150-55f2581c0194",
    knownAuthorities: ["https://login.microsoftonline.com"],
    // authority: "https://login.microsoftonline.com/baae5256-5a73-40f2-8c4d-76f3ae971463",
    // clientId: "f5b30356-db09-4783-9402-bba4662df75b",
    // aud: "f5b30356-db09-4783-9402-bba4662df75b",
    // knownAuthorities: ["https://login.microsoftonline.com"],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
export const msalConfigProd = {
  auth: {
    authority: "https://login.microsoftonline.com/baae5256-5a73-40f2-8c4d-76f3ae971463",
    clientId: "f5b30356-db09-4783-9402-bba4662df75b",
    aud: "f5b30356-db09-4783-9402-bba4662df75b",
    knownAuthorities: ["https://login.microsoftonline.com"],
    // authority: "https://login.microsoftonline.com/baae5256-5a73-40f2-8c4d-76f3ae971463",
    // clientId: "f5b30356-db09-4783-9402-bba4662df75b",
    // aud: "f5b30356-db09-4783-9402-bba4662df75b",
    // knownAuthorities: ["https://login.microsoftonline.com"],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ["openid"],
};

export const userLocations = {
  // DEV TENANT
  "f625e942-6dc4-4719-9b78-ac94dab639f3": "ADJALA",
  "6ffc260c-85b2-44cf-9c92-8662ac703705": "ADJALA-TOSORONTIO",
  "1ff4133c-087d-4472-8ed9-92847df30546": "BARRIE",
  "9d208efc-25e4-4ed8-b706-c27e883ea40c": "BEAUSOLEIL-FIRST-NATION",
  "a2211f93-7d36-41f6-91ac-cd725f6fcaff": "BRADFORD-WEST-GWILLIMBURY",
  "c82f74d2-d9ca-49fa-a10f-0714c3c7f067": "BWG",
  "876c6b12-57fa-43ca-be4a-1799aa4f3d3d": "CFB-BORDEN",
  "7e3358bd-caf5-49fa-9882-174f23e923b4": "CHIPPEWAS-OF-RAMA-FIRST-NATION",
  "04d1c6f1-a0f2-4466-aa7f-12137ddbc322": "CLEARVIEW",
  "195aca2c-dd8f-4d50-bdf7-7a038b02bfdf": "CLEARVIEW",
  "7ee49e48-0ae7-47f1-a30d-6ec2e3b3b86b": "COLLINGWOOD",
  "114bfc72-ede1-4d08-a160-f5cfdd04d13c": "COLLINGWOOD",
  "f5bb2068-bb63-4eec-8fd8-182c869ebb57": "COUNTY",
  "ed67b301-7ddc-41a6-8e56-97c2f41b9b31": "ESSA",
  "870f9d6f-2bbe-488d-aa3a-ca85721f4420": "ESSA",
  "7ac7f240-2d29-460e-893c-19d2aba4531e": "INNISFIL",
  "053af85f-00f5-4ba6-9a71-216b815841ed": "INNISFIL",
  "b47f0023-8846-4f5c-a94d-1ea3c79163d5": "MIDLAND",
  "cd186b08-60b5-49ce-af82-08c087df1b89": "MIDLAND",
  "43be8efd-b5b8-4acc-b941-9ae33926e1a0": "NEW-TECUMSETH",
  "baa5f016-b35e-472b-82a2-7829103ff691": "NEWTEC",
  "16211faf-f8f2-423c-9799-a902c5d61dde": "ORILLIA",
  "c59ca6e2-d2f9-4c11-9941-b964fcf06191": "ORILLIA",
  "55366b0c-0855-4f52-b9b1-2653688ec076": "ORO",
  "3f335ecf-3ab3-46fe-9085-adfe4e62fb0c": "ORO-MEDONTE",
  "9457c379-4060-439e-8818-884551388d40": "PENETANG",
  "2375a25f-7bc6-4e6c-9c60-2b0fbaa2d2df": "PENETANGUISHENE",
  "72bbefd5-b2f9-4dab-82f9-171b6e79fc89": "RAMARA",
  "9f9cd892-b447-4d9d-8b78-8465d10043cf": "RAMARA",
  "1f55a630-57a0-47c9-a500-98d2f277523e": "SEVERN",
  "96f4eb37-c769-4026-a038-e1bacdc64986": "SEVERN",
  "b014c65e-5220-430a-a6c9-901fc9c7f27f": "COUNTY OF SIMCOE",
  "21212ea8-2edd-458c-ad75-a2af5e9016d3": "SPRINGWATER",
  "e06701d6-5787-4064-8691-d587f40a24d6": "SPRINGWATER",
  "15dbfbaf-eed8-4ba3-9421-881e14e01ec9": "TAY",
  "a7ca26fe-7ce2-4325-a3e6-5aa3f8fba1ce": "TAY",
  "f7b28eac-f9a8-4a23-a398-22dba141f031": "TINY",
  "ffa74aa7-d0a1-41fc-aed4-a354b11ff080": "TINY",
  "426c84e7-33ec-48dd-8501-bd704359a0bd": "WASAGA",
  "5bfa3dab-8f74-4638-8192-6e260f01207f": "WASAGA-BEACH",

  //Added "All Web Analyst Employees" as County of Simcoe Group to allow for testing
  "1afb98d6-69a8-4248-b9b2-1d70e2ab93da": "COUNTY OF SIMCOE",

  // PROD User Location GUIDs
  "43197fc0-0a22-4cae-a0b9-9a03dfeb80f8": "ADJALA-TOSORONTIO",
  "04a73dc0-b973-4bd2-a6c6-a141feaca287": "BARRIE",
  "c0da946a-6828-4b86-ab03-7ae2cef95153": "BRADFORD WEST GWILLIMBURY",
  "8743ca5e-6134-47ba-8649-87078094972c": "CLEARVIEW",
  "2eaa550c-1317-404d-9379-8f658d490053": "COLLINGWOOD",
  "00b90ad1-5090-4190-8d50-0b6fa8e941be": "COUNTY OF SIMCOE",
  "2900272a-594f-447e-ad26-ac663f0de042": "COUNTY OF SIMCOE",
  "f26d9a31-5781-4211-bfe0-ce5ca9971efb": "COUNTY OF SIMCOE",
  "e155d654-2d48-4273-9bcb-16a7245c28d8": "ESSA",
  "563c4c57-0107-4712-9241-03bd67f52433": "INNISFIL",
  "c7d75538-56ea-4470-b6b9-8645431b15e8": "MIDLAND",
  "6ebf371e-7e64-41fc-8670-e3e3001e55f0": "NEW TECUMSETH",
  "06b16bb2-7235-49a4-9f2c-97879f33c3f8": "ORILLIA",
  "d7322ce2-5b1c-44e9-8265-b8bb8cf9c5e7": "ORO-MEDONTE",
  "1e22e3e6-494a-40b0-86e9-d5719216fe57": "PENETANGUISHENE",
  "0b897ad4-5019-4640-a456-7b2a46f7627c": "RAMARA",
  "07cf7c9c-421d-47f8-8e2a-430f1649bae7": "SEVERN",
  "a7936f92-e1fc-4bc3-9d7d-73ce481d807a": "SPRINGWATER",
  "a1afcc35-326d-4228-ba70-d13147237dc7": "TAY",
  "85ab39bc-e128-4f9c-999a-cf96c76d5bf0": "TINY",
  "6bf699aa-c638-4076-8cb2-9d73a7ee931d": "WASAGA BEACH",
};
export const getUserLocations = () => {
  let locations = [];
  const userGroups = window.account && window.account.idTokenClaims ? window.account.idTokenClaims.groups : [];
  userGroups.forEach((groupId) => {
    const lookupValue = userLocations[groupId];
    if (lookupValue) locations.push(lookupValue);
  });
  return locations;
};

import React from "react";
import "./UserProfile.css";
import { Button, Typography } from "@mui/material";
import { useMsal, UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { msalConfig, getUserLocations } from "../authConfig";

function handleLogin(instance) {
  instance.loginRedirect(msalConfig).catch((e) => {
    console.error(e);
  });
}

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const UserProfile = () => {
  const { instance } = useMsal();
  return (
    <div className="sc-user-profile">
      <AuthenticatedTemplate>
        <div className="userInfo">
          <div className="userFlex">
            <Typography align="center" variant="subtitle2">
              {window.account ? window.account.name : ""}
            </Typography>
            <Typography align="center" variant="caption">
              {window.account ? `(${getUserLocations()[0]})` : ""}
            </Typography>
          </div>
          <Button variant="contained" color="secondary" onClick={() => handleLogout(instance)} title={`Logout of ${window.account ? window.account.username : ""}`}>
            Logout
          </Button>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button variant="contained" color="secondary" onClick={() => handleLogin(instance)} title="Login">
          Login
        </Button>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default UserProfile;

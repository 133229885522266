import { ButtonGroup, Grid, TextField } from "@mui/material";
import { AddCircleOutlineOutlined, BackspaceRounded } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import * as validateForm from "../../helpers/validateForm";
import StreetSearch from "../../ResusableComponents/StreetSearch";
import ButtonWithTooltip from "../../ResusableComponents/ButtonWithTooltip";
import MunicipalityDropDown from "../../ResusableComponents/MunicipalityDropDown";
import AddressContext from "../../Contexts/AddressContext";

const AddTemplate = ({}) => {
  const { addressTemplate, setAddressTemplate, setAddressesFromTemplate, PRIMARYLOCATION } = useContext(AddressContext);
  const [errors, setErrors] = useState({});
  const [formStyle, setFormStyle] = useState({ background: "rgba(116,217,116,0)", borderRadius: "1rem", padding: "0.5rem", paddingBottom: "0.15rem" });
  const [formError, setFormError] = useState("");
  const [firstLoad, setFirstLoad] = useState(0);

  useEffect(() => {
    setAddressTemplate(addressTemplate);
  }, [addressTemplate]);

  useEffect(() => {
    if (firstLoad === 0) {
      setFirstLoad(firstLoad + 1);
    } else {
      setFormStyles();
    }
  }, [errors]);

  const setFormStyles = () => {
    if (formValidation()) {
      setFormError("");
      setFormStyle({ ...formStyle, background: "rgba(116,217,116,0.25)" });
    } else {
      setFormStyle({ ...formStyle, background: "rgba(252,91,91,0.25)" });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    let isValid = {};

    if (name === "street") {
      setAddressTemplate({ ...addressTemplate, [name]: value.StreetFullName, settlement: value.MuniName });
      setErrors({ ...errors, [name]: error });
    } else {
      setAddressTemplate({ ...addressTemplate, [name]: value });

      //Validate the Values
      if (value.length < 1 && name !== "fromStreet") {
        setErrors({ ...errors, [name]: error });
        setFormStyles();
      } else {
        if (name === "propertyNum") {
          isValid = validateForm.validateARN(value);
        } else if (name === "planNum") {
          isValid = validateForm.validatePlan(value);
        } else if (name === "fromStreet") {
          if (value.length === 0 && addressTemplate.increment > 0) {
            error = "A starting street number is requried for auto incrementing";
          }
        } else if (name === "increment") {
          if (value > 0 && addressTemplate.fromStreet === "") {
            setErrors({ ...errors, fromStreet: "A starting street number is requried for auto incrementing" });
            return;
          } else {
            setErrors({ ...errors, fromStreet: "" });
            return;
          }
        }

        setErrors({ ...errors, [name]: isValid.errorMsg });
      }
    }
  };

  const _DoesRowHaveErrors = () => {
    return !Object.values(errors).every((err) => err === "" || err === undefined);
  };

  const clearTemplate = () => {
    setAddressTemplate({
      propertyNum: "",
      street: "",
      settlement: PRIMARYLOCATION,
      commonName: "",
      fromStreet: "",
      planNum: "",
      increment: 0,
      quantity: 1,
    });
    setErrors({});
    setFormStyle({ ...formStyle, background: "rgba(116,217,116,0)" });
  };

  const formValidation = () => {
    let isValid = (addressTemplate.propertyNum || addressTemplate.planNum) && !_DoesRowHaveErrors();
    if (isValid === undefined || isValid === "") {
      isValid = false;
    }

    if (addressTemplate.settlement === "COUNTY OF SIMCOE") {
      setFormError("Municipality can not be set to the County of Simcoe");
      return false;
    }

    if (addressTemplate.propertyNum === "" && addressTemplate.planNum === "") {
      setFormError("An ARN/Property # or a Plan # is required");
    }

    console.log(isValid);

    return isValid;
  };

  const onSubmit = (e) => {
    console.log(errors);
    if (formValidation()) {
      setAddressesFromTemplate(addressTemplate);
      clearTemplate();
    } else {
      if ((addressTemplate.propertyNum || addressTemplate.planNum) === undefined) setFormError("Property #/ARN or Plan # is required");
      else if (addressTemplate.propertyNum !== undefined && addressTemplate.propertyNum !== "") setFormError("Ensure Property #/ARN is formatted correctly");
      else if (addressTemplate.planNum === undefined || addressTemplate.planNum === "") setFormError("Ensure Plan Number is formatted correctly");
      else if (addressTemplate.settlement === "COUNTY OF SIMCOE") setFormError("Municipality can not be set to the County of Simcoe");
      else setFormError("Ensure there are no field errors before submitting");
    }
  };

  return (
    <form name="templateForm">
      <Grid container spacing={2} style={formStyle}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            autoComplete="off"
            name="propertyNum"
            fullWidth
            id="templatePropertyNum"
            label="Property #/ARN"
            variant="outlined"
            onChange={handleChange}
            value={addressTemplate.propertyNum}
            error={Boolean(errors?.propertyNum)}
            helperText={errors?.propertyNum}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <StreetSearch name="street" value={addressTemplate.street} setValue={handleChange} muni={PRIMARYLOCATION} />
        </Grid>
        {PRIMARYLOCATION !== "COUNTY OF SIMCOE" && (
          <Grid item xs={12} sm={6} md={4} lg={PRIMARYLOCATION === "COUNTY OF SIMCOE" ? 1 : 2}>
            <TextField name="commonName" autoComplete="off" fullWidth id="templateCommonName" label="Common Name" variant="outlined" onChange={handleChange} value={addressTemplate.commonName} />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={1}>
          <TextField
            name="planNum"
            autoComplete="off"
            fullWidth
            id="templatePlanNum"
            label="Plan #"
            variant="outlined"
            onChange={handleChange}
            value={addressTemplate.planNum}
            error={Boolean(errors?.planNum)}
            helperText={errors?.planNum}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={1}>
          <TextField
            name="fromStreet"
            autoComplete="off"
            fullWidth
            id="templateFromStreet"
            label="Street #"
            variant="outlined"
            onChange={handleChange}
            value={addressTemplate.fromStreet}
            error={Boolean(errors?.fromStreet)}
            helperText={errors?.fromStreet}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={1}>
          <TextField
            error={Boolean(errors?.increment)}
            helperText={errors?.increment}
            autoComplete="off"
            type="number"
            name="increment"
            fullWidth
            id="templateIncrement"
            label="Increment By"
            variant="outlined"
            onChange={handleChange}
            value={addressTemplate.increment}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={1}>
          <TextField
            required
            name="quantity"
            error={Boolean(errors?.quantity)}
            helperText={errors?.quantity}
            autoComplete="off"
            type="number"
            fullWidth
            id="templateQuantity"
            label="Quantity"
            variant="outlined"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={handleChange}
            value={addressTemplate.quantity}
          />
        </Grid>
        {PRIMARYLOCATION === "COUNTY OF SIMCOE" && (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <MunicipalityDropDown id="MuniName" name="settlement" value={addressTemplate.settlement} updateFunction={handleChange} label="Municipality" variant={"outlined"} required={true} />
          </Grid>
        )}
        <Grid item xs={12} md={4} lg={1} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
          <ButtonGroup>
            <ButtonWithTooltip tooltipText="Use Template Values for Editing" variant="contained" color="primary" onClick={onSubmit} endIcon={<AddCircleOutlineOutlined />}>
              {"Add"}
            </ButtonWithTooltip>
            <ButtonWithTooltip tooltipText="Clear Template Values" variant="contained" color="secondary" onClick={clearTemplate}>
              <BackspaceRounded />
            </ButtonWithTooltip>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "0.5rem", textAlign: "center", color: "Red" }}>
          {formError}
        </Grid>
      </Grid>
    </form>
  );
};

export default AddTemplate;

import "./AddEditStreets.css";

import { Container, Paper, Typography } from "@mui/material";
import { Assessment } from "@mui/icons-material";
import StreetSearchFullDetails from "./StreetSearchFullDetails";
import AddEditStreetsPanel from "./AddEditStreetPanel";
import { useEffect, useState } from "react";
import * as helpers from "../helpers/helpers.js";
import { AppConfig } from "../config";
import { toast } from "react-toastify";

const insertStreetURL = () => AppConfig.apiUrl + `secure/address/addEditStreetToCAM`;

const streetTemplate = {
  DirectionPrefixId: null,
  DirectionPrefixName: "",
  PrefixId: null,
  PrefixName: "",
  StreetID: null,
  StreetName: "",
  SuffixID: null,
  StreetType: "",
  DirectionID: null,
  DirectionName: "",
  MuniId: null,
  MuniName: "",
  StreetFullName: "",
};
const AddEditStreets = () => {
  const [street, setStreet] = useState(streetTemplate);
  const [editMode, setEditMode] = useState("");

  useEffect(() => {}, [street]);

  const handleFullStreetChange = (e) => {
    setStreet(e);
  };

  const handleEditModeChange = (e) => {
    setEditMode(e);
    if (!e || e === "ADD") setStreet(streetTemplate);
  };

  const handleSave = (e) => {
    setEditMode("");
    setStreet(streetTemplate);

    console.log(insertStreetURL());
    helpers.getBearerToken((bearer) => {
      if (bearer.error) {
        toast.error("Error getting Authorization Token", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "AddressRowToken",
          className: "errorToast",
        });
        return;
      }

      helpers.getJSONWaitWithParams(
        insertStreetURL(),
        {
          method: "POST",
          mode: "cors",
          headers: { Authorization: bearer, "Content-Type": "application/json" },
          body: JSON.stringify(e),
        },
        (res) => {
          //TODO: Add toasts below
          console.log(res);
          if (res.result !== "") {
            toast.error(res.result, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "errorToast",
            });
          } else {
            toast.success("Operation Successfull!", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "successToast",
            });
          }
        }
      );
    });
  };

  const handleCancel = (e) => {
    setEditMode("");
    setStreet(streetTemplate);
  };

  return (
    <main>
      <Container maxWidth="xl">
        <Paper elevation={2} className="customPaper">
          <div className="iconAndText pageTitle">
            <Assessment style={{ fontSize: 43 }} color="primary" />
            <Typography variant="h5">Add/Edit Streets In CAM</Typography>
          </div>
          <div className="content">
            <StreetSearchFullDetails street={street} onFullStreetChange={handleFullStreetChange} onEditModeChange={handleEditModeChange} editMode={editMode} />
            <AddEditStreetsPanel editStreet={street} editMode={editMode} onSave={handleSave} onCancel={handleCancel} />
          </div>
        </Paper>
      </Container>
    </main>
  );
};

export default AddEditStreets;

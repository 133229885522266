//Import System and App dependencies
import { useState, useContext } from "react";
import { AppConfig } from "../config";
import "./AddressManagement.css";
import * as helpers from "../helpers/helpers";

//Import Components
import AddressEditor from "./AddressEditor/AddressEditor";
import Search from "./Search/Search";
import AddressContext, { AddressProvider } from "../Contexts/AddressContext";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, CircularProgress, Container, Paper, Typography } from "@mui/material";
import { EditLocation, ExpandMoreRounded, NotListedLocation, Streetview } from "@mui/icons-material";

// URLs
const apiUrl = AppConfig.apiUrl;

const AddressManagement = () => {
  const [screenLoading, setScreenLoading] = useState(false);

  return (
    <main>
      <Container maxWidth="xl">
        <Paper elevation={2} className="customPaper">
          <div className="iconAndText pageTitle">
            <NotListedLocation style={{ fontSize: 43 }} color="primary" />
            <Typography variant="h4"> Address Management</Typography>
          </div>
          <div className="accordion">
            <AddressProvider>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="search-content" id="search-header" className="accordionHeader">
                  <div className="iconAndText">
                    <Streetview fontSize="medium" color="primary" />
                    <Typography variant="h6">&nbsp;Search</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Search apiURL={apiUrl} />
                </AccordionDetails>
              </Accordion>
              {helpers.isAllowed(window.security, ["edit"]) && (
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="addressEditor-content" id="addressEditor-header" className="accordionHeader">
                    <div className="iconAndText">
                      <EditLocation fontSize="medium" color="primary" />
                      <Typography variant="h6">&nbsp;Address Editor</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AddressEditor setScreenLoading={setScreenLoading} apiURL={apiUrl} />
                  </AccordionDetails>
                </Accordion>
              )}
            </AddressProvider>
          </div>
          <Backdrop
            style={{ zIndex: 100 }}
            open={screenLoading}
            onClick={() => {
              setScreenLoading(false);
            }}
          >
            <CircularProgress />
          </Backdrop>
        </Paper>
      </Container>
    </main>
  );
};

export default AddressManagement;

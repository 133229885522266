import shortid from "shortid";
import GeoJSON from "ol/format/GeoJSON.js";
import { AppConfig } from "../config";
import { msalConfig } from "../authConfig";

export function getBearerToken(callback) {
  window.instance
    .acquireTokenSilent({
      account: window.account,
      authority: msalConfig.auth.authority,
      scopes: [AppConfig.azureScope],
    })
    .then((response) => {
      callback(`bearer ${response.accessToken}`);
    })
    .catch((error) => {
      window.instance
        .acquireTokenRedirect({
          account: window.account,
        })
        .catch((error) => {
          console.error(error);
          callback({ error: 1 });
        });
    });
}

// GET JSON (NO WAITING)
export function getJSON(url, callback) {
  return fetch(url)
    .then((response) => response.json())
    .then((responseJson) => {
      // CALLBACK WITH RESULT
      if (callback !== undefined) callback(responseJson);
    })
    .catch((error) => {
      console.error("Error: ", error, "URL:", url);
      callback({ result: { error: 1 } });
    });
}

// GET JSON (NO WAITING)
export function getJSONWithParams(url, params = undefined, callback) {
  return fetch(url, params)
    .then((response) => response.json())
    .then((responseJson) => {
      // CALLBACK WITH RESULT
      if (callback !== undefined) callback(responseJson);
    })
    .catch((error) => {
      console.error("Error: ", error, "URL:", url);
      callback({ result: { error: 1 } });
    });
}
// GET JSON WAIT
export async function getJSONWaitWithParams(url, params = undefined, callback) {
  let data = await await fetch(url, params)
    .then((res) => {
      const resp = res.json();
      //console.log(resp);
      return resp;
    })
    .catch((err) => {
      console.log("Error: ", err, "URL:", url);
      callback({ result: { error: 1 } });
    });
  if (callback !== undefined) {
    callback(data);
  }

  return await data;
}
// GET JSON WAIT
export async function getJSONWait(url, callback) {
  let data = await await fetch(url)
    .then((res) => {
      const resp = res.json();
      //console.log(resp);
      return resp;
    })
    .catch((err) => {
      console.log("Error: ", err, "URL:", url);
      callback({ result: { error: 1 } });
    });
  if (callback !== undefined) {
    //console.log(data);
    callback(data);
  }

  return await data;
}

// GET JSON WITH TOKEN (NO WAITING)
export function getJSONWithToken(url, callback) {
  getBearerToken((token) => {
    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        // CALLBACK WITH RESULT
        callback(responseJson);
      })
      .catch((error) => {
        console.log("Error - " + url);
        console.log(error);
        callback({ result: { error: 1 } });
      });
  });
}

// HTTP GET (NO WAITING)
export function httpGetText(url, callback) {
  return fetch(url)
    .then((response) => response.text())
    .then((responseText) => {
      // CALLBACK WITH RESULT
      if (callback !== undefined) callback(responseText);
    })
    .catch((error) => {
      //httpGetText(url.replace("opengis.simcoe.ca", "opengis2.simcoe.ca"), callback);
      console.error(url, error);
      if (callback !== undefined) callback();
    });
}
// HTTP GET (NO WAITING)
export function httpGetTextWithParams(url, params = undefined, callback) {
  return fetch(url, params)
    .then((response) => response.text())
    .then((responseText) => {
      // CALLBACK WITH RESULT
      if (callback !== undefined) callback(responseText);
    })
    .catch((error) => {
      //httpGetText(url.replace("opengis.simcoe.ca", "opengis2.simcoe.ca"), callback);
      console.error(url, error);
      if (callback !== undefined) callback();
    });
}
export const messageColors = {
  gray: "gray",
  green: "green",
  blue: "blue",
  red: "red",
  yellow: "yellow",
  orange: "orange",
};

// URL FRIENDLY STRING ID
export function getUID() {
  return shortid.generate();
}

//https://opengis.simcoe.ca/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=simcoe:Bag%20Tag%20Locations&outputFormat=application/json
export function getWFSGeoJSON(serverUrl, layerName, callback, sortField = null, extent = null, cqlFilter = null, count = null) {
  // SORTING
  let additionalParams = "";
  if (sortField !== null) additionalParams += "&sortBy=" + sortField;

  // BBOX EXTENT
  if (extent !== null && (cqlFilter === null || cqlFilter.length === 0)) {
    const extentString = extent[0] + "," + extent[1] + "," + extent[2] + "," + extent[3];
    additionalParams += "&bbox=" + extentString;
  }

  // ATTRIBUTE WHERECLAUSE
  if (cqlFilter !== null && cqlFilter.length !== 0) {
    additionalParams += "&cql_filter=" + cqlFilter;
    if (extent !== null) {
      const extentString = extent[0] + "," + extent[1] + "," + extent[2] + "," + extent[3];
      additionalParams += " AND BBOX(geom, " + extentString + ")";
    }
  }

  // COUNT
  if (count !== null) additionalParams += "&count=" + count;

  // USE TEMPLATE FOR READABILITY
  const wfsUrlTemplate = (serverURL, layerName) => `${serverURL}wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=${layerName}&outputFormat=application/json`;
  const wfsUrl = wfsUrlTemplate(serverUrl, layerName) + additionalParams;
  getJSON(wfsUrl, (result) => {
    const geoJSON = new GeoJSON().readFeatures(result);
    callback(geoJSON);
  });
}
export function isAllowed(userRoles, requestedAccess) {
  if (!userRoles) return false;
  if (!Array.isArray(requestedAccess)) {
    return userRoles.includes(requestedAccess);
  } else {
    return requestedAccess.some((item) => {
      return userRoles.includes(item);
    });
  }
}

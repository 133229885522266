import { MenuItem, TextField } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

const MunicipalityDropDown = ({ name, value, updateFunction, id, label, variant, required, hasError, errorText }) => {
  const municipalities = [
    {
      value: "COUNTY OF SIMCOE",
      text: "County of Simcoe",
    },
    {
      value: "ADJALA-TOSORONTIO",
      text: "Adjala-Tosorontio",
    },
    {
      value: "BRADFORD WEST GWILLIMBURY",
      text: "Bradford West Gwillimbury",
    },
    {
      value: "CLEARVIEW",
      text: "Clearview",
    },
    {
      value: "COLLINGWOOD",
      text: "Collingwood",
    },
    {
      value: "ESSA",
      text: "Essa",
    },
    {
      value: "INNISFIL",
      text: "Innisfil",
    },
    {
      value: "MIDLAND",
      text: "Midland",
    },
    {
      value: "NEW TECUMSETH",
      text: "New Tecumseth",
    },
    {
      value: "ORO-MEDONTE",
      text: "Oro-Medonte",
    },
    {
      value: "PENETANGUISHENE",
      text: "Penetanguishene",
    },
    {
      value: "RAMARA",
      text: "Ramara",
    },
    {
      value: "SEVERN",
      text: "Severn",
    },
    {
      value: "SPRINGWATER",
      text: "Springwater",
    },
    {
      value: "TAY",
      text: "Tay",
    },
    {
      value: "TINY",
      text: "Tiny",
    },
    {
      value: "WASAGA BEACH",
      text: "Wasaga Beach",
    },
  ];
  return (
    // <StyledEngineProvider injectFirst>
    <div sx={{ width: "100%", maxWidth: "240px" }}>
      <TextField fullWidth select id={id} name={name} label={label} value={value} onChange={updateFunction} required={required} variant={variant} error={hasError} helperText={errorText}>
        {municipalities.map((muni) => (
          <MenuItem key={muni.value} value={muni.value}>
            {muni.text}
          </MenuItem>
        ))}
      </TextField>
    </div>
    // </StyledEngineProvider>
  );
};

MunicipalityDropDown.defaultProps = {
  variant: "standard",
  required: false,
};

export default MunicipalityDropDown;

import { TextField } from "@mui/material";
import { Autocomplete, ButtonGroup, Grid } from "@mui/material";
import ButtonWithTooltip from "../ResusableComponents/ButtonWithTooltip";
import { Edit, Clear, Add } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { AppConfig } from "../config";
import * as helpers from "../helpers/helpers";

const StreetSearchFullDetails = ({ street, onFullStreetChange, onEditModeChange, editMode }) => {
  const [options, setOptions] = useState([]);

  const getSearchOptionsURL = (searchTerm) => AppConfig.apiUrl + `secure/search/searchStreetsFullDetails/${encodeURIComponent(searchTerm)}`;

  useEffect(() => {
    console.log("in use effect");
  }, [street]);

  const handleChange = (e, value, reason) => {
    onFullStreetChange(value);
  };

  const updateSearchOptions = (e) => {
    if (e.length >= 2) {
      helpers.getJSONWithToken(getSearchOptionsURL(e), (opts) => {
        setOptions(opts.result);
      });
    }
  };

  const handleEditStreetButton = () => {
    onFullStreetChange(street);
    onEditModeChange("EDIT");
  };

  const handleClearButton = () => {
    onEditModeChange("");
  };

  const handleAddButton = () => {
    onEditModeChange("ADD");
  };

  return (
    <Grid container spacing={2} style={{ borderRadius: "1rem", marginBottom: "1rem", padding: "0.5rem", paddingBottom: "0.15rem", backgroundColor: "rgba(252,91,91, 0)" }}>
      <Autocomplete
        key={"street-search-full-details-auto-complete"}
        style={{ width: "70%" }}
        disableClearable
        disabled={editMode !== "" ? true : false}
        value={street}
        options={options}
        onChange={handleChange}
        clearOnBlur={true}
        getOptionLabel={(option) => (option.StreetFullName === "" ? "" : option.StreetFullName + " - " + option.MuniName)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Search Street To Edit Existing"
            onChange={(e) => {
              updateSearchOptions(e.target.value);
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={helpers.getUID()}>
            <div>
              {option.StreetFullName}
              <div style={{ fontSize: "12px", fontWeight: "bold" }}>{option.MuniName}</div>
            </div>
          </li>
        )}
      />
      <ButtonGroup style={{ marginTop: "5px" }}>
        <ButtonWithTooltip
          disabled={street.StreetFullName === "" || editMode !== "" ? true : false}
          tooltipText={"Edit This Street"}
          style={{ width: "50px", height: "auto", marginLeft: "0.5rem" }}
          variant="contained"
          color="secondary"
          onClick={() => {
            handleEditStreetButton();
          }}
        >
          <Edit fontSize="large" />
        </ButtonWithTooltip>
        <ButtonWithTooltip
          disabled={editMode === "EDIT" || editMode === "ADD" ? true : false}
          tooltipText={"Add New Street"}
          style={{ width: "50px", height: "auto", marginLeft: "0.5rem" }}
          variant="contained"
          color="secondary"
          onClick={() => {
            handleAddButton();
          }}
        >
          <Add fontSize="large" />
        </ButtonWithTooltip>
        <ButtonWithTooltip
          disabled={street.StreetFullName === "" || editMode !== "" ? true : false}
          tooltipText={"Clear"}
          style={{ width: "50px", height: "auto", marginLeft: "0.5rem" }}
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClearButton();
          }}
        >
          <Clear fontSize="large" />
        </ButtonWithTooltip>
      </ButtonGroup>
    </Grid>
  );
};

export default StreetSearchFullDetails;
